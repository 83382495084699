import { BomGroup, WorksheetCompletionType } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'

export enum BomGroupRelations {
    Boms = 'boms',
    BomGroupAssemblyFields = 'bomGroupAssemblyFields',
    BomGroupAssemblyFieldsFields = 'bomGroupAssemblyFieldsFields',
}

export interface GetBomGroupsDto {
    relations?: BomGroupRelations[]
    ids?: number[]
    name?: string
    withDeleted?: boolean
    skip?: number
    limit?: number
}

export const getBomGroupByIdRequest = (
    id: number,
    dto: GetBomGroupsDto
): Promise<Response<BomGroup>> =>
    backendConnection.request({
        url: `/bom-groups/${id}`,
        params: dto,
        method: 'GET',
    })

export const getBomGroupsRequest = (
    dto: GetBomGroupsDto
): Promise<Response<{ entities: BomGroup[]; count: number }>> =>
    backendConnection.request({
        url: `/bom-groups`,
        params: dto,
        method: 'GET',
    })

export interface UpdateBomGroupDto {
    name?: string
    /**
     * The order of the lines matters, and will be used to determine the order of the lines in the worksheet.
     */
    lines?: number[]
    assemblyTransferEnabled?: boolean
    assemblyEnabled?: boolean
    createPalletEnabled?: boolean
}

export const updateBomGroupRequest = (
    id: number,
    dto: UpdateBomGroupDto
): Promise<Response<BomGroup>> =>
    backendConnection.request({
        url: `/bom-groups/${id}`,
        data: dto,
        method: 'PUT',
    })

export const deleteBomGroupsRequest = (bomGroupId: number): Promise<Response<BomGroup>> =>
    backendConnection.request({
        url: `/bom-groups/${bomGroupId}`,
        method: 'DELETE',
    })

export interface CreateBomGroupDto {
    worksheetCompletionType: WorksheetCompletionType
    name: string
    assemblyTransferEnabled: boolean
    assemblyEnabled: boolean
    createPalletEnabled: boolean
    /**
     * The order of the lines matters, and will be used to determine the order of the lines in the worksheet.
     */
    lines?: number[]
}

export const createBomGroupRequest = (bomGroup: CreateBomGroupDto): Promise<Response<BomGroup>> =>
    backendConnection.request({
        url: `/bom-groups`,
        method: 'POST',
        data: bomGroup,
    })
