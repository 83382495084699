import { BinHolding, Product, Supplier } from '../models/models'
import { Response } from './util/with-response-formatter-interceptor'
import backendConnection from './util/backend-connection'
import { Changes } from '../components/stock-manager/AddStockTrackingModal'

export type BomType = 'FLATSTATION' | 'PT40' | 'OTHER'
export enum ProductFilter {
    Hiu = 'Hiu',
    Flatstation = 'Flatstation',
    Other = 'Other',
    Stock = 'Stock',
    None = 'None',
}
interface FilterStockItemQueryParams {
    likeCode?: string
    likeName?: string
    likeGroup?: string
    likeCodeOrName?: string
}

export interface GetStockItemQueryParams extends FilterStockItemQueryParams {
    skip?: number
    includeSageStock?: boolean
    productGroupCode?: string
    hasSageStockWithWarehouseIds?: number[]
    limit?: number
    trackedWarehouseIds?: number[]
    isTracked?: boolean
    ids?: number[]
    excludeIds?: number[]
    withCount?: boolean
    productTypeFilter?: ProductFilter
    userId?: number
}

export interface UpdateStockItemBody {
    isStockTracked: boolean
}

export const getStockItemsRequest = (
    params: GetStockItemQueryParams
): Promise<Response<{ entities: Product[]; count: number }>> =>
    backendConnection.request({
        url: `/stock-items`,
        method: 'GET',
        params,
    })

export const pinStockItemRequest = (stockItemId: number): Promise<Response<Product>> => {
    return backendConnection.request({
        url: `/stock-items/pin/${stockItemId}`,
        method: 'PUT',
    })
}

export interface GetBinHoldingsQueryParamsDTO {
    warehouseId?: number
}

export const getBinHoldingsRequest = (
    productId: number,
    params?: GetBinHoldingsQueryParamsDTO
): Promise<Response<BinHolding[]>> =>
    backendConnection.request({
        url: `/stock-items/bin-holdings/${productId}`,
        method: 'GET',
        params,
    })

export enum StockItemRelation {
    Bom = 'Bom',
    BuilItemComponents = 'BuiltItemComponents',
}

export const getStockItemByIdRequest = (
    id: number,
    relations?: StockItemRelation[]
): Promise<Response<Product>> =>
    backendConnection.request({
        url: `/stock-items/${id}`,
        method: 'GET',
        params: { relations },
    })

export const updateTrackedWarehouses = (updates: Changes): Promise<Response<Product>> =>
    backendConnection.request({
        url: `/stock-items/tracking-warehouses`,
        method: 'PUT',
        data: updates,
    })

export const getStockItemsCount = (params: FilterStockItemQueryParams): Promise<Response<number>> =>
    backendConnection.request({
        url: `/stock-items/count`,
        method: 'GET',
        params,
    })

export const updateStockItem = (
    stockItemId: number,
    updateStockItemBody: UpdateStockItemBody
): Promise<Response<number>> =>
    backendConnection.request({
        url: `/stock-items/${stockItemId}`,
        method: 'PUT',
        data: updateStockItemBody,
    })

export interface HIUComponent {
    value: string
    codeSequence: string
    id: string
}

export interface GetHiuCodeDTO {
    series: HIUComponent
    standard: HIUComponent
    dhw?: HIUComponent
    radiatorOrFloor?: HIUComponent
    heatingPlate?: HIUComponent
    pipe?: HIUComponent
    ppv?: HIUComponent
    coverAndInsulation?: HIUComponent
    bespokings?: HIUComponent[]
}

export interface HIUOptions {
    readonly seriesOptions: HIUComponent[]
    readonly standardOptions: HIUComponent[]
    readonly dhwOptions: HIUComponent[]
    readonly heatingTypeOptions: HIUComponent[]
    readonly heatingPlateOptions: HIUComponent[]
    readonly ppvOptions: HIUComponent[]
    readonly pipeConfigurationOptions: HIUComponent[]
    readonly insulationOptions: HIUComponent[]
    readonly bespokingOptions: HIUComponent[]
}

export interface ProductGroup {
    id: number
    code: string
    description: string
    productType: string
    canLevelsGoNegative: boolean
    dateTimeUpdated: Date
}
export const getProductGroupsRequest = (code?: string): Promise<Response<ProductGroup[]>> =>
    backendConnection.request({
        url: `/stock-items/product-groups`,
        method: 'GET',
        params: { code },
    })

export const getProductGroupByIdRequest = (id: number): Promise<Response<ProductGroup>> =>
    backendConnection.request({
        url: `/stock-items/product-groups/${id}`,
        method: 'GET',
    })

export const getProductGroupByCodeRequest = (code: string): Promise<Response<ProductGroup>> =>
    backendConnection.request({
        url: `/stock-items/product-groups/code/${code}`,
        method: 'GET',
    })

export const getByCode = (code: string): Promise<Response<Product>> =>
    backendConnection.request({
        url: `/stock-items/code/${code}`,
        method: 'GET',
    })

export interface HiuDetails {
    series: HIUComponent
    standard: HIUComponent
    dhw: HIUComponent
    heatingType: HIUComponent
    heatingPlate: HIUComponent
    ppv: HIUComponent
    pipeConfiguration: HIUComponent
    insulation: HIUComponent
    bespoking: HIUComponent
}
export const getHiuDetailsRequest = (id: number): Promise<Response<HiuDetails>> =>
    backendConnection.request({
        url: `/stock-items/hiu/details/${id}`,
        method: 'GET',
    })

export const getConflictStockItems = (): Promise<Response<Product[]>> =>
    backendConnection.request({
        url: `/stock-items/conflict`,
        method: 'GET',
    })

export const getStockItemByCode = (code: string): Promise<Response<Product>> =>
    backendConnection.request({
        url: `/stock-items/code`,
        method: 'GET',
        params: { code },
    })

export const getAwaitingBomCreationStockItems = (): Promise<Response<Product[]>> =>
    backendConnection.request({
        url: `/stock-items/authorization/awaiting-bom-creation`,
        method: 'GET',
    })

export const getByDescription = (description: string): Promise<Response<Product>> =>
    backendConnection.request({
        url: `/stock-items/description?description=${description}`,
        method: 'GET',
    })

export const getByName = (name: string): Promise<Response<Product>> =>
    backendConnection.request({
        url: `/stock-items/name?name=${name}`,
        method: 'GET',
    })

export const getSuppliers = (search?: string): Promise<Response<Supplier[]>> =>
    backendConnection.request({
        url: `/stock-items/suppliers`,
        method: 'GET',
        params: { search },
    })
