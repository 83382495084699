import { UpdateUserDto } from '../api/users'

export enum WarehouseId {
    Monument = 1,
    Scandia = 2,
}

export enum MomentDays {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export enum AuthorizationState {
    Completed = 'Completed',
    Unauthorized = 'Unauthorized',
}

export enum BomTypes {
    BuiltBought = 'EnumBOMItemTypeBuiltBought',
    Phantom = 'EnumBOMItemTypePhantom',
    Built = 'EnumBOMItemTypeStocked',
    Stock = 'EnumBOMItemTypeComponent',
}

export interface BaseEntity {
    isOnHold: boolean
    isArchived: boolean
    timeCreatedAt: Date
    timeUpdatedAt: Date
    timeDeletedAt: Date
}

export interface Organisation extends BaseEntity {
    id: number
    organizationReference: string
    name: string
    streetAddress: string
    town: string
    postcode: string
    country: string
    creditStatus: string
    phoneNumber: string
    email: string
}

export interface Pallet extends BaseEntity {
    id: number
    datePlaced: Date
    projectId: number
    project: Project
    projectManagerId: number
    projectManager?: User
    deliveryId: number
    delivery: Delivery
    warehouseAreaSpotId: number
    palletProducts: PalletProduct[]
    events: PalletEvent[]
    warehouseAreaSpot: WarehouseAreaSpot
    assemblyId: number
    assembly: Assembly
    comment: string
    systemComment: string
    stackLevel: number
}

export interface BomComponent extends BaseEntity {
    id: number
    amount: number
    bomId: number
    builtItem: Bom
    productId: number
    product: Product
}

export interface Warehouse extends BaseEntity {
    id: number
    name: string
    assemblyMinutesDaily: number
    areas: string[]
    users: User[]
    callOffs: CallOff[]
    stockTrackedStockItems: Product[]
    orders: PurchaseOrder[]
    sageWarehouses: SageWarehouse[]
    pallets: Pallet[]
}

export interface WarehouseAreaSpot extends BaseEntity {
    id: number
    x: number
    y: number
    maxStackLevel: number
    assemblerId: number
    assembler?: User
    isCreationSpot: boolean
    warehouseAreaId: number
    warehouseArea: WarehouseArea
    pallets: Pallet[]
}

export interface WarehouseArea extends BaseEntity {
    id: number
    name: number
    warehouseId: number
    warehouse: Warehouse
    warehouseAreaSpot: WarehouseAreaSpot[]
}

export interface PalletProduct extends BaseEntity {
    id: number
    productId: number
    amount: number
    product: Product
    palletId: number
    pallet: Pallet
}

export interface PalletEvents extends BaseEntity {
    author?: User
    authorId: number
    comment: string
    fromSpot: FromToSpot
    fromSpotId: number
    id: number
    isArchived: boolean
    isOnHold: boolean
    palletId: number
    timeCreatedAt: Date
    toSpot: FromToSpot
    toSpotId: number
    type: string
}

export interface FromToSpot {
    id: number
    x: number
    y: number
    timeCreatedAt: Date
}

export interface Delivery {
    id: number
    receivedDate: Date
    orderConfirmationId: number
    orderConfirmation: OrderConfirmation
    goodsDelivered: GoodsDelivered[]
    deliveryId: number
    pallets: Pallet[]
}

export interface GoodsDelivered {
    id: number
    amountReceived: number
    ocOrderlineId: number
    ocOrderline: OrderConfirmationOrderline
    ocDeliveryId: number
    ocDelivery: Delivery
}

export enum DaysOffType {
    Holiday = 'HOLIDAY',
    SickLeave = 'SICK_LEAVE',
    DayOffInLieu = 'DAY_OFF_IN_LIEU',
    MaternityPaternityLeave = 'MATERNITY_PATERNITY_LEAVE',
}

export enum OrderType {
    Bulk = 'Bulk',
    Project = 'Project',
}

export interface DaysOff {
    id: number
    type: DaysOffType
    absentDays: AbsentDay[]
    userId: number
    user?: User
}

export enum AbsentDayType {
    Minutes = 'Minutes',
    HalfDay = 'HalfDay',
    FullDay = 'FullDay',
}

export interface AbsentDay {
    id: number
    date: Date
    type: AbsentDayType
    subtractedMinutesScheduleAmount: number
    daysOffId: number
    daysOff: DaysOff
}

export interface OpportunityStatus {
    id: number
    name: string
    hexColor: string
}

export enum UserRole {
    Assembler = 'ASSEMBLER',
    ProjectManager = 'PROJECT_MANAGER',
    SavAdmin = 'SAV_ADMIN',
    SystemAdmin = 'SYSTEM_ADMIN',
    AccountManager = 'ACCOUNT_MANAGER',
    LogisticsEmployee = 'LOGISTICS_EMPLOYEE',
    ApplicationEngineer = 'APPLICATIONS_ENGINEER',
    Scheduler = 'SCHEDULER',
}

export interface GeneralAssembly extends BaseEntity {
    id: number
    description: string
    dateToBeCompletedBy: Date
    completedByUserId: number
    completedByUser: User
    createdByUserId: number
    createdByUser: User
    activities: AssemblyActivity[]
    completedAt: Date
    warehouseId: number
    warehouse: Warehouse
    builtItemId: number
    builtItem: Bom
    lines: GeneralAssemblyLine[]
}

export interface GeneralAssemblyLine extends BaseEntity {
    id: number
    generalAssemblyId: number
    generalAssembly: GeneralAssembly
    completedAt: Date
    assembledById: number
    assembledBy: User
    note: string
    serial: string
}

export enum InputFieldType {
    TextInput = 'TEXT_INPUT',
    Dropdown = 'DROPDOWN',
}

export interface SetUserInput {
    key: keyof UpdateUserDto
    inputType: InputFieldType
}

export interface User {
    id: number
    firstName: string
    lastName: string
    activities: AssemblyActivity[]
    role: UserRole
    /**
     * @description How many minutes the user will default add to the production-schedule on a monday
     * since the production-schedule is how many resources there are available for assemblies,
     * this column is most likely restricted to assemblers.
     * @author Jonas Grønbek
     * @date 23/09/2022
     */
    scheduledMinutesMonday: number

    /**
     * @description How many minutes the user will default add to the production-schedule on a tuesday
     * since the production-schedule is how many resources there are available for assemblies,
     * this column is most likely restricted to assemblers.
     * @author Jonas Grønbek
     * @date 23/09/2022
     */
    scheduledMinutesTuesday: number

    /**
     * @description How many minutes the user will default add to the production-schedule on a wednesday
     * since the production-schedule is how many resources there are available for assemblies,
     * this column is most likely restricted to assemblers.
     * @author Jonas Grønbek
     * @date 23/09/2022
     */
    scheduledMinutesWednesday: number

    /**
     * @description How many minutes the user will default add to the production-schedule on a thursday
     * since the production-schedule is how many resources there are available for assemblies,
     * this column is most likely restricted to assemblers.
     * @author Jonas Grønbek
     * @date 23/09/2022
     */
    scheduledMinutesThursday: number

    /**
     * @description How many minutes the user will default add to the production-schedule on a friday
     * since the production-schedule is how many resources there are available for assemblies,
     * this column is most likely restricted to assemblers.
     * @author Jonas Grønbek
     * @date 23/09/2022
     */
    scheduledMinutesFriday: number
    email: string | null
    phoneNumber: string | null
    assemblerSpots: WarehouseAreaSpot[]
    country: string | null
    timeCreatedAt?: string
    self?: boolean
    verifiedAt: string | null
    warehouseId: number
    warehouse: Warehouse
    stockItemPins: Product[]
}

export enum ContactType {
    Client = 'CLIENT',
    Consultant = 'CONSULTANT',
    Contractor = 'CONTRACTOR',
}

export interface Contact {
    id: number
    name: string
    type: ContactType
    address: string
    email: string
    phoneNumber: string
}

export interface CreditStatus {
    id: number
    name: string
}

export interface StockItemRequest {
    isActive: boolean
    isArchived: boolean
    timeCreatedAt: Date
    timeUpdatedAt: Date
    timeDeletedAt?: Date
    id: number
    code: string
    createdProduct: Product
    createdProductId?: number
    type: string
    description?: string
    name?: string
    requestedByUser?: User
    requestedByUserId: number
    requestedForProject: Project
    requestedForProjectId: number
}

export interface Project extends BaseEntity {
    id: number
    opportunityReference: string
    name: string
    valueInGBP: number
    address: string
    projectBuiltItem: ProjectBuiltItem[]
    projectStockItem: ProjectStockItem[]
    callOffs: CallOff[]
    opportunityStatusId: number
    opportunityStatus: OpportunityStatus
    creditStatusId: number
    isPinned: boolean
    creditStatus: CreditStatus
    postcode: string
    accountManagers: User[]
    applicationsEngineers: User[]
    projectManagers: User[]
    contacts: Contact[]
    clients: Organisation[]
    consultants: Organisation[]
    contractors: Organisation[]
    customerId: number
    customer?: Customer
    events: PalletEvent[]
    pallets: Pallet[]
    documents: ProjectDocument[]
}

export enum ProjectEventType {
    UpdatedOpportunityStatus = 'UPDATED_OPPORTUNITY_STATUS',
    UpdatedCreditStatus = 'UPDATED_CREDIT_STATUS',
    RemovedUser = 'REMOVED_USER',
    AddedUser = 'ADDED_USER',
    SubmittedDocumentVersion = 'SUBMITTED_DOCUMENT_VERSION',
    DispatchedCallOff = 'DISPATCHED_CALL_OFF',
}

export interface ProjectEvent extends BaseEntity {
    id: number
    type: ProjectEventType
    authorId: number
    author?: User
    callOffId: number
    callOff: CallOff
    projectId: number
    userId: number
    opportunityStatusId: number
    projectStatusId: number
    creditStatus: CreditStatus
    opportunityStatus: OpportunityStatus
    documentVersion: ProjectDocumentVersion
    user?: User
}

export interface ProjectDocumentType {
    id: number
    name: string
}

export interface ProjectDocument {
    id: number
    type: ProjectDocumentType
    creatorUser?: User
    timeCreatedAt: Date
    isArchived: boolean
    versions: ProjectDocumentVersion[]
}

export interface ProjectDocumentVersion {
    id: number
    name: string
    version: number
    comment: string
    storageKey: string
    storageUrl: string
    creatorUser?: User
    submitterUser?: User
    timeSubmittedAt: Date
    timeCreatedAt: Date
    isArchived: boolean
    document: ProjectDocument | null
}

export interface ProjectStockItem {
    isActive: boolean
    isArchived: boolean
    timeCreatedAt: Date
    timeUpdatedAt: Date
    timeDeletedAt?: Date
    id: number
    projectId: number
    amount: number
    stockItemId: number
    stockItem: Product
    used?: number
}

export interface SageBomType extends BaseEntity {
    id: number
    value: string
    description: string
}

export interface Product extends BaseEntity {
    isPinned?: boolean
    id: number
    sageConflict: number
    bom: Bom
    code: string
    name: string
    description: string
    useDescriptionOnDocuments: boolean
    statusType: string
    statusTypeId: number
    traceable_type: string
    traceableTypeId: number
    bomItemTypeId: number
    bomType: SageBomType
    saleFromSingleBatch: boolean
    allowDuplicateNumbers: boolean
    usesSellByDate: boolean
    usesUseByDate: boolean
    recordNosOnGoodsReceived: boolean
    allowSalesOrder: boolean
    sellingPrice: number
    useDescriptionOnDocs: boolean
    standardCost: number
    sopItemPrice: number
    inactiveDate: Date
    suppressNetMass: boolean
    stock_multiple_of_base_unit: number
    usesAlternativeRef: boolean
    fulfilmentMethodType: string
    fulfilmentMethodTypeId: number
    defaultDespatchNoteComment: string
    defaultPickingListComment: string
    autoGenerateOptionType: string
    autoGenerateOptionTypeId: number
    autoGeneratePrefix: string
    autoGenerateNextNumber: number
    labelPrintingOptionType: string
    labelPrintingOptionTypeId: number
    fulfilmentSequenceType: string
    fulfilmentSequenceTypeId: number
    shelfLife: number
    productGroupCode: string
    productGroupDescription: string
    productGroupDoItemsUseMarkup: boolean
    productGroupProductType: string
    productGroupProductTypeId: number
    thisIsTheStandardBand: boolean
    productGroupCostingMethodType: string
    productGroupCostingMethodTypeId: number
    averageBuyingPrice: number
    landedCostsValue: number
    manufacturer: string
    partNumber: string
    stockTakeCyclePeriod: number
    stockUnitName: string
    baseUnitName: string
    commodityCode: string
    weight: number
    freeStockQuantity: number
    quantityReserved: number
    confirmedQuantityInStock: number
    unconfirmedQuantityInStock: number
    actualQuantity: number
    quantityAllocatedSop: number
    quantityAllocatedStock: number
    quantityAllocatedBom: number
    quantityOnPopOrder: number
    dateOfLastStockCount: Date
    reorderLevel: number
    price: number
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
    dateOfLastSale?: Date
    authorizationRequestedUser?: User
    authorizationRequestedUserId?: number
    authorizationRequestedForProject?: Project
    authorizationRequestedForProjectId?: number
    hiuBuiltItems?: Bom[]
    authorizationState: AuthorizationState
    sageDateTimeUpdated: Date
    isBodyValve: boolean
    bodyValveBuiltItems: Bom[]
    isBodyValveFitting: boolean
    bodyValveFittingBuiltItems: Bom[]
    isLockshieldValve: boolean
    lockshieldValveBuiltItems: Bom[]
    isLockshieldValveFitting: boolean
    lockshieldValvFittingeBuiltItems: Bom[]
    isSensorHead: boolean
    sensorHeadBuiltItems: Bom[]
    isAccessory: boolean
    accessoryBuiltItems: Bom[]
    isExtra: boolean
    isPt40: boolean
    isHiu: boolean
    isFlatstation: boolean
    isOther: boolean
    isWiring: boolean
    isEnergyMeter: boolean
    isPpa: boolean
    unauthorizedExtraBomComponents: UnauthorizedExtraBomComponent[]
    extraBomComponents: ExtraBomComponent[]
    palletProducts: PalletProduct[]
    builtItemBomSequence: string
    projectStockItems: ProjectStockItem[]
    stockItemOrderlines: SopStockItemOrderline[]
    purchaseOrderOrderlines: PurchaseOrderOrderline[]
    stockTrackingWarehouses: Warehouse[]
    bomComponents: BomComponent[]
    binHoldings: BinHolding[]
    pinnedByUsers: User[]
}

export interface BinHolding {
    id: number
    warehouseHoldingId: number
    productId: number
    name: string
    allocationPriority: number
    quantityReserved: number
    unconfirmedQtyInStock: number
    confirmedQtyInStock: number
    quantityAllocatedSop: number
    quantityAllocatedStock: number
    quantityAllocatedBom: number
    quantityInStock: number
    quantityInStockLessReserved: number
    quantityAllocated: number
    freeStockAvailable: number
    freeStockAvailableLessReserved: number
    lastStocktake: Date
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface PurchaseOrderOrderline {
    id: number
    lineQuantity: number

    /**
     * @description The allocated amount means delivered + aggregate amounts on active OC's.
     * After delivery of an OC it will be recorded in sage, and added to the
     * receiptReturnedQuantity property.
     * This value is used to figure out how much should be expected to be put on new OC's.
     * @date 02/01/2023
     * @memberof PurchaseOrderOrderline
     */
    allocatedAmount: number

    /**
     * @description The delivered amount is the amount recorded delivered in sage + the amount
     * recorded delivered on active OC's.
     * @date 02/01/2023
     * @memberof PurchaseOrderOrderline
     */
    deliveredAmount: number

    //The amount that is recoreded deliverd in sage
    receiptReturnQuantity: number

    purchaseOrderId: number
    purchaseOrder: PurchaseOrder
    goodsDelivered: GoodsDelivered[]
    productId: number
    product: Product
    warehouse: SageWarehouse
    warehouseId: number
    lineNumber: number
    lineType: string
    code: string
    description: string
    supplierPartRef: string
    forDirectDelivery: boolean
    showOnSupplierDocs: boolean
    confirmationIntentType: string
    taxCodeId: number
    invoiceCreditQuantity: number
    buyingUnitDescription: string
    unitBuyingPrice: number
    unitDiscountPercent: number
    unitDiscountValue: number
    lineTotalValue: number
    lineTaxValue: number
    analysisCodes: AnalysisCodes
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface OrderConfirmationOrderline {
    id: number
    amount: number
    //calculated in application, not a column
    //it is how much is yet to be delivered
    deliveredAmount: number
    orderConfirmationId: number
    orderConfirmation: OrderConfirmation
    goodsDelivered: GoodsDelivered[]
    purchaseOrderOrderlineId: number
    purchaseOrderOrderline: PurchaseOrderOrderline
}

export interface OrderConfirmation extends BaseEntity {
    id: number
    code: string
    deliveredDate: Date
    expectedCompletelyArrivedDate: Date
    authorId: number
    author?: User
    purchaseOrderId: number
    purchaseOrder: PurchaseOrder
    callOffId: number
    callOff: CallOff
    deliveries: Delivery[]
    notifications: Notification[]
    orderlines: OrderConfirmationOrderline[]
}

export interface PurchaseOrder {
    supplierDispatchDate: Date
    expectedDeliveryDate: Date
    deliveredDate: Date
    receivedDate: Date
    returnedDate: Date
    warehouseId: number
    markedArrivedById: number
    markedArrivedBy?: User
    activatedDate: Date
    activatedById: number
    activatedBy?: User
    warehouse: Warehouse
    type: OrderType
    markedReceivedBy?: User
    orderConfirmations: OrderConfirmation[]
    id: number
    documentNo: string
    orderlines: PurchaseOrderOrderline[]
    documentDate: Date
    documentStatus: string
    authorisationStatus: string
    supplierId: number
    exchangeRate: number
    subtotalGoodsValue: number
    subtotalChargesNetValue: number
    subtotalDiscountValue: number
    subtotalLandedCostsValue: number
    totalNetValue: number
    totalTaxValue: number
    totalGrossValue: number
    supplierDocumentNo: string
    settlementDiscountDays: number
    settlementDiscountPercent: number
    documentDiscountPercent: number
    documentOriginatorId: number
    documentOriginatorName: string
    documentCreatedById: number
    documentCreatedBy: string
    supplierReference: string
    supplierName: string
    supplierShortName: string
    supplierOnHold: boolean
    analysisCodes: AnalysisCodes
    dateTimeUpdated: Date
    isOnHold: boolean
}

export interface StockViewWeek {
    periodStart: number
    consumption: number
    ordered: number
}

export enum PalletEventType {
    Moved = 'Moved',
    Merged = 'Merged',
    Split = 'Split',
    //unassigned is when a pallet is unassigned from calloff
    Unassigned = 'Unassigned',
    ProductAmountsAdjusted = 'ProductAmountsAdjusted',
    Created = 'Created',
    Deleted = 'Deleted',
    Updated = 'Updated',
    AssemblyTransfer = 'AssemblyTransfer',
    AssemblyCompleted = 'AssemblyCompleted',
    DispatchTransfer = 'DispatchTransfer',
    CallOffDispatch = 'CallOffDispatch',
}
export interface PalletEvent extends BaseEntity {
    id: number
    type: PalletEventType
    pallet: Pallet
    palletId: number
    author?: User
    authorId: number
    fromSpot: WarehouseAreaSpot
    fromSpotId: number
    toSpot: WarehouseAreaSpot
    toSpotId: number
    comment: string
    assemblyId: number
    assembly: Assembly
    projectId: number
}

export interface StockView {
    isActive: boolean
    isArchived: boolean
    timeCreatedAt: Date
    timeUpdatedAt: Date
    timeDeletedAt?: Date
    id: number
    sageProductId: number
    code: string
    name: string
    description: string
    sageDateTimeUpdated: string
    isBodyValve?: boolean
    isBodyValveFitting?: boolean
    isLockshieldValve: boolean
    isLockshieldValveFitting?: boolean
    isSensorHead?: boolean
    isAccessory?: boolean
    isExtra?: boolean
    builtItemBomSequence: string
    sageStocks: SageStock[]
    outgoingSalesOrders: number
    incomingPurchaseOrders: number
    week1: StockViewWeek
    week2: StockViewWeek
    week3: StockViewWeek
    week4: StockViewWeek
}

export interface SageStock {
    id: number
    sageId: number
    reorderLevel: number
    minimumLevel: number
    maximumLevel: number
    quantityInStock: number
    confirmedQuantityInStock: number
    quantityAllocatedStock: number
    quantityAllocatedSop: number
    quantityAllocatedBom: number
    quantityAllocated: number
    warehouseId: number
    warehouse: SageWarehouse
    productId: number
    stockItem: Product
}

export interface SageWarehouse {
    id: number
    sageName: string
    sageWarehouseId: number
    sageDateTimeUpdated: Date
    warehouseId: number
    warehouse: Warehouse
    sageStocks: SageStock[]
}

export interface ProjectBuiltItem {
    isActive: boolean
    isArchived: boolean
    timeCreatedAt: Date
    timeUpdatedAt: Date
    timeDeletedAt?: Date
    id: number
    projectId: number
    amount: number
    builtItemId: number
    builtItem: Bom
    used?: number
}

export enum HIUStandard {
    TermixOneB = 'Termix_One_B',
    TermixNovi = 'Termix_Novi',
    TermixBL = 'Termix_BL',
    TermixBVT = 'Termix_BV_T',
    TermixBVE = 'Termix_BV_E',
    TermixBespoke = 'Termix_Bespoke',
    TermixVXFI = 'Termix_VX_FI',
    TermixVXZ = 'Termix_VX_Z',
    TermixVXCompact28T = 'Termix_VX_Compact_28_T',
    TermixVXBespoke = 'Termix_VX_Bespoke',
    TermixVXC = 'Termix_VX_C',
    TermixVXCCompact28 = 'Termix_VX_C_Compact_28',
    TermixVXCBespoke = 'Termix_VX_C_Bespoke',
    TermixVMTDIRFI = 'Termix_VMTD_I_R_FI',
    TermixVMTDIRFIMix = 'Termix_VMTD_I_R_FI_Mix',
    TermixVMTDFB = 'Termix_VMTD_F_B',
    TermixVMTDBespoke = 'Termix_VMTD_Bespoke',
    TermixVVXIRFI = 'Termix_VVX_I_R_FI',
    TermixVVXIV = 'Termix_VVX_IV',
    TermixVVXCompact28 = 'Termix_VVX_Compact_28/18T',
    TermixVVXCompact28T = 'Termix_VVX_Compact_28_T',
    TermixVVXB = 'Termix_VVX_B',
    TermixVVXBespoke = 'Termix_VVX_Bespoke',
}

export enum PT40Material {
    Copper = 'Copper',
    Pex = 'Pex',
    Steel = 'Steel',
}

export interface AssemblyLineField extends BaseEntity {
    id: number
    value: string
    assemblyLineId: number
    assemblyLine: AssemblyLine
    assemblyFieldId: number
    assemblyField: AssemblyField
    submittedById: number
    submittedBy: User
    submittedAt: Date
}

export enum FieldType {
    Text = 'Text',
    Numeric = 'Numeric',
    Dropdown = 'Dropdown',
    Boolean = 'Boolean',
}

export interface AssemblyField extends BaseEntity {
    id: number
    assemblyLineFields: AssemblyLineField[]
    bomGroupAssemblyFields: BomGroupAssemblyField[]
    name: string
    type: FieldType
    isRequired: boolean
    isUnique: boolean
    isSerial: boolean
    dropdownOptions: string[]
    isScannable: boolean
    numericUpperLimit: number
    numericLowerLimit: number
    matchingRegex: string
}

export enum WorksheetCompletionType {
    CollectiveComplete = 'CollectiveComplete',
    IndividualComplete = 'IndividualComplete',
}

export interface BomGroupAssemblyField extends BaseEntity {
    id: number
    columnOrder: number
    bomGroupId: number
    bomGroup: AssemblyLine
    assemblyFieldId: number
    assemblyField: AssemblyField
}

export interface BomGroup extends BaseEntity {
    id: number
    name: string
    worksheetCompletionType: WorksheetCompletionType
    boms: Bom
    bomGroupAssemblyFields: BomGroupAssemblyField[]
    assemblyEnabled: boolean
    assemblyTransferEnabled: boolean
    createPalletEnabled: boolean
}

export interface Bom extends BaseEntity {
    id: number
    bomCode: string
    product: Product
    assemblyMinutes: number
    requestedByUser?: User
    requestedByUserId?: number
    requestedForProject?: Project
    requestedForProjectId?: number
    instructionLink: string
    groupId?: number
    group?: BomGroup
    activeBomVersionId: number
    activeBomVersion: BomVersion
    bomVersions: BomVersion[]
    bomComponents: BomComponent[]
    projectBuiltItems: ProjectBuiltItem[]
    builtItemOrderlines: SopBuiltItemOrderline[]
    generalAssemblies: GeneralAssembly[]
}

export enum CallOffStatus {
    Requested = 'REQUESTED',
    Approved = 'APPROVED',
    InProduction = 'IN_PRODUCTION',
    Completed = 'COMPLETED',
    Dispatched = 'DISPATCHED',
}

export interface Schedule {
    day: Date
    assemblers: User[]
    assemblies: Assembly[]
    generalAssemblies: GeneralAssembly[]
    absentDays: AbsentDay[]
    tasks: Task[]
}

export interface SopBuiltItemOrderline extends BaseEntity {
    id: number
    lineType: LineType
    salesOrderId: number
    analysisCodes: AnalysisCodes
    salesOrder: SalesOrder
    amount: number
    lineNumber: number
    code: string
    description: string
    useDescription: boolean
    taxCodeId: number
    nominalReference: string
    nominalCostCentre: string
    nominalDepartment: string
    allocatedQuantity: number
    despatchReceiptQuantity: number
    invoiceCreditQuantity: number
    postedInvoiceCreditQuantity: number
    availableForDespatch: number
    stockUnitLineQuantity: number
    stockUnitAllocatedQuantity: number
    stockUnitDespatchReceiptQuantity: number
    stockUnitInvoiceCreditQuantity: number
    stockUnitPostedInvoiceCreditQuantity: number
    stockUnitAvailableForDespatch: number
    allocationStatus: string
    despatchReceiptStatus: string
    invoiceCreditStatus: string
    sellingUnitDescription: string
    pricingUnitDescription: string
    sellingUnitPrice: number
    unitDiscountPercent: number
    unitDiscountValue: number
    costPrice: number
    discountPercentSpecified: boolean
    sellingUnitPriceOverridden: boolean
    unitDiscountOverridden: boolean
    pickingListComment: string
    despatchNoteComment: string
    showOnCustomerDocs: boolean
    showOnPickingListType: string
    confirmationIntentType: string
    hasPopOrder: boolean
    isComplete: boolean
    lineTotalValue: number
    lineTaxValue: number
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    toAllocateQuantity?: number
    stockUnitToAllocateQuantity?: number
    sellingUnitId?: number
    pricingUnitId?: number
    discountedUnitPrice?: number
    retainManualPrices?: boolean
    fulfilmentMethod: string
    markAsPreferred?: boolean
    product: Product
    productId?: number
    warehouseId?: number
    builtItemCallOffs: BuiltItemCallOff[]
    builtItemId: number
    builtItem: Bom
    //how many are reserved by undespatched call offs, not on entity. Can be undefined if not retrieved explicitly from api
    reserved: number
}

export interface SopStockItemOrderline extends BaseEntity {
    id: number
    lineType: LineType
    salesOrderId: number
    analysisCodes: AnalysisCodes
    salesOrder: SalesOrder
    amount: number
    lineNumber: number
    code: string
    description: string
    useDescription: boolean
    taxCodeId: number
    nominalReference: string
    nominalCostCentre: string
    nominalDepartment: string
    allocatedQuantity: number
    despatchReceiptQuantity: number
    invoiceCreditQuantity: number
    postedInvoiceCreditQuantity: number
    availableForDespatch: number
    stockUnitLineQuantity: number
    stockUnitAllocatedQuantity: number
    stockUnitDespatchReceiptQuantity: number
    stockUnitInvoiceCreditQuantity: number
    stockUnitPostedInvoiceCreditQuantity: number
    stockUnitAvailableForDespatch: number
    allocationStatus: string
    despatchReceiptStatus: string
    invoiceCreditStatus: string
    sellingUnitDescription: string
    pricingUnitDescription: string
    sellingUnitPrice: number
    unitDiscountPercent: number
    unitDiscountValue: number
    costPrice: number
    discountPercentSpecified: boolean
    sellingUnitPriceOverridden: boolean
    unitDiscountOverridden: boolean
    pickingListComment: string
    despatchNoteComment: string
    showOnCustomerDocs: boolean
    showOnPickingListType: string
    confirmationIntentType: string
    hasPopOrder: boolean
    isComplete: boolean
    lineTotalValue: number
    lineTaxValue: number
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    toAllocateQuantity?: number
    stockUnitToAllocateQuantity?: number
    sellingUnitId?: number
    pricingUnitId?: number
    discountedUnitPrice?: number
    retainManualPrices?: boolean
    fulfilmentMethod: string
    markAsPreferred?: boolean
    product: Product
    productId?: number
    warehouseId?: number
    stockItemCallOffs: StockItemCallOff[]
    //how many are reserved by undespatched call offs, not on entity. Can be undefined if not retrieved explicitly from api
    reserved: number
}

export interface BuiltItemCallOff extends BaseEntity {
    id: number
    amount: number
    callOffId: number
    callOff: CallOff
    builtItemSopId: number
    sopBuiltItemOrderline: SopBuiltItemOrderline
    assemblies: Assembly[]
    goodsDispatched: GoodsDispatched[]
}

export interface StockItemCallOff extends BaseEntity {
    id: number
    amount: number
    dispatchTransferredDate: Date
    callOffId: number
    callOff: CallOff
    stockItemSopId: number
    sopStockItemOrderline: SopStockItemOrderline
    goodsDispatched: GoodsDispatched[]
}

export enum DispatchedGoodsType {
    BuiltItem = 'BuiltItem',
    StockItem = 'StockItem',
}

export interface GoodsDispatched {
    id: number
    type: DispatchedGoodsType
    amountDispatched: number
    builtItemCallOffId: number
    builtItemCallOff: BuiltItemCallOff
    stockItemCallOffId: number
    stockItemCallOff: StockItemCallOff
    dispatchId: number
    dispatch: Dispatch
    assemblyLinesDispatched: AssemblyLine[]
}

export interface Dispatch {
    id: number
    courier: string
    consignmentNumber: string
    note: string
    dispatchedDate: Date
    callOffId: number
    callOff: CallOff
    dispatchedById: number
    dispatchedBy: User
    goodsDispatched: GoodsDispatched[]
    pallets: Pallet[]
}

export interface CallOff extends BaseEntity {
    id: number
    projectId: number
    isDelayed: boolean
    isOnHold: boolean
    isAllGoodsIn: boolean
    customerDispatchDate: Date
    dateToBeCompletedBy: Date
    deliveryDate: Date
    status: CallOffStatus
    salesOrder: SalesOrder
    contacts: Contact[]
    salesOrderId: number
    dispatchComment: string
    dispatchedDate: Date
    warehouseId: number
    warehouse: Warehouse
    project: Project
    comments: string[]
    builtItemCallOffs: BuiltItemCallOff[]
    stockItemCallOffs: StockItemCallOff[]
    assemblies: Assembly[]
    orderConfirmations: OrderConfirmation[]
    timeEstimate: number
    title: string
    goodsInDate: Date
    dispatches: Dispatch[]
}

export type PT40AssemblyMinutes = 10 | 15
export type HIUAssemblyMinutes = number
export type OtherAssemblyMinutes = number
export type BuiltItemAssemblyMinutes =
    | HIUAssemblyMinutes
    | OtherAssemblyMinutes
    | PT40AssemblyMinutes

export interface Assembly extends BaseEntity {
    id: number
    dateToBeCompletedBy: Date
    assemblyTransferDate: Date
    completedAssemblyDate: Date
    dispatchTransferDate: Date
    completedAt: Date | null
    startedAt: Date
    amount: number
    callOffId: number
    callOff: CallOff
    startedByUserId: number
    startedByUser: User
    markedCompletedByUserId: number
    assembler: User
    note: string | null
    activities: AssemblyActivity[]
    lines: AssemblyLine[]
    builtItemCallOffId: number
    builtItemCallOff: BuiltItemCallOff
    notifications: Notification[]
    pallets: Pallet[]
    plotNumbers: PlotNumber[]
    assemblyComment: string
}

export enum ActivityType {
    GeneralAssembly = 'GeneralAssembly',
    Assembly = 'Assembly',
}

export interface AssemblyActivity extends BaseEntity {
    id: number
    comment: string
    type: ActivityType
    assemblyId: number
    assembly: Assembly
    generalAssemblyId: number
    generalAssembly: GeneralAssembly
    endedAt: Date | null
    startedAt: Date
    userId: number
    user: User
}

export enum AssemblyLineStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
}

export enum DocumentStatus {
    EnumDocumentStatusLive = 'Live',
    EnumDocumentStatusOnHold = 'On hold',
    EnumDocumentStatusComplete = 'Completed',
    EnumDocumentStatusDispute = 'Disputed',
    EnumDocumentStatusCancelled = 'Cancelled',
    EnumDocumentStatusDraft = 'Draft',
    EnumDocumentStatusPrinted = 'Printed',
}

export type MEGGER_VALUES =
    | '101586440'
    | '102047199'
    | '101961925'
    | '102047197'
    | '101766161'
    | '101961924'

export enum InsulationTestValue {
    GREATER_THAN_1000 = 'GREATER_THAN_1000',
    LESS_THAN_OR_EQUAL_TO_1000 = 'LESS_THAN_OR_EQUAL_TO_1000',
}

export interface AssemblyLineValidation extends BaseEntity {
    id: number
    line: AssemblyLine
    testedBy: User
    testedOn: Date
    note: string | null
    visualCheck: boolean | null
    flyingLeadsCheck: boolean | null
    meggerMIT3000SN: MEGGER_VALUES
    continuityTestEarthPumpBody: number | null
    continuityTestEarthGuruEarth: number | null
    continuityTestEarthFrame: number | null
    insulationTest: InsulationTestValue | null
    functionalTest: boolean | null
}

export interface AssemblyLine extends BaseEntity {
    id: number
    status: AssemblyLineStatus
    assemblyLineFields: AssemblyLineField[]
    assemblyId: number
    assembly: Assembly
    completedAt: Date
    assemblerId: number
    assembler: User | null
    returnedAt: Date | null
    returnedBy: User | null
    returnedComment: string | null
    reworkedAt: Date | null
    reworkedBy: User | null
    reworkedEnergyMeterSerialNumber: string | null
    reworkedHiuSerialNumber: string | null
    goodsDispatchedId: number
    goodsDispatched: GoodsDispatched
}

export interface SopDeliveryAddress {
    //comes from sage
    id: number
    salesOrders: SalesOrder[]
    contact: string
    postalName: string
    country: string
    telephoneNumber: string
    faxNumber: string
    emailAddress: string
    taxNumber: string
    taxCodeId: number
    countryCodeId: number
    address1: string
    address2: string
    address3: string
    address4: string
    city: string
    county: string
    postcode: string
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface SopContact {
    id: number
    name: string
    description: string
    phoneNumber: string
    salesOrderId: number
    salesOrder: SalesOrder
}

export interface SalesOrder {
    id: number
    projectId: number
    project: Project
    warehouseId: number
    contacts: SopContact[]
    warehouse: Warehouse
    customerId: number
    customer: Customer
    builtItemOrderlines: SopBuiltItemOrderline[]
    stockItemOrderlines: SopStockItemOrderline[]
    commentOrderlines: SopCommentOrderline[]
    chargeOrderlines: SopChargeOrderline[]
    freeTextOrderlines: SopFreeTextOrderline[]
    callOffs: CallOff[]
    isCreditLimitExceeded: boolean
    paymentWithOrder: boolean
    paymentValue: number
    paymentMethodId: number
    note: string
    documentNo: string
    documentDate: Date
    documentStatus: string
    customerType: string
    currencyId: number
    isDraft: boolean
    exchangeRate: number
    subtotalGoodsValue: number
    subtotalChargeNetValue: number
    subtotalChargeTaxValue: number
    subtotalDiscountValue: number
    totalNetValue: number
    totalTaxValue: number
    totalGrossValue: number
    customerDocumentNo: string
    useInvoiceAddress: boolean
    settlementDiscountDays: number
    settlementDiscountPercent: number
    documentDiscountPercent: number
    documentCreatedBy: string
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    orderPriority: string
    analysisCodes: AnalysisCodes
    externalReference: string
    deliveryAddress: SopDeliveryAddress
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface SopFreeTextOrderline extends BaseEntity {
    id: number
    lineType: LineType
    salesOrderId: number
    analysisCodes: AnalysisCodes
    salesOrder: SalesOrder
    amount: number
    lineNumber: number
    code: string
    description: string
    useDescription: boolean
    taxCodeId: number
    nominalReference: string
    nominalCostCentre: string
    nominalDepartment: string
    allocatedQuantity: number
    despatchReceiptQuantity: number
    invoiceCreditQuantity: number
    postedInvoiceCreditQuantity: number
    availableForDespatch: number
    stockUnitLineQuantity: number
    stockUnitAllocatedQuantity: number
    stockUnitDespatchReceiptQuantity: number
    stockUnitInvoiceCreditQuantity: number
    stockUnitPostedInvoiceCreditQuantity: number
    stockUnitAvailableForDespatch: number
    allocationStatus: string
    despatchReceiptStatus: string
    invoiceCreditStatus: string
    sellingUnitDescription: string
    pricingUnitDescription: string
    sellingUnitPrice: number
    unitDiscountPercent: number
    unitDiscountValue: number
    costPrice: number
    discountPercentSpecified: boolean
    sellingUnitPriceOverridden: boolean
    unitDiscountOverridden: boolean
    pickingListComment: string
    despatchNoteComment: string
    showOnCustomerDocs: boolean
    showOnPickingListType: string
    confirmationIntentType: string
    hasPopOrder: boolean
    isComplete: boolean
    lineTotalValue: number
    lineTaxValue: number
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    toAllocateQuantity?: number
    stockUnitToAllocateQuantity?: number
    sellingUnitId?: number
    pricingUnitId?: number
    discountedUnitPrice?: number
    retainManualPrices?: boolean
    fulfilmentMethod: string
    markAsPreferred?: boolean
    product: Product
    productId?: number
    warehouseId?: number
    chargeId: number
    chargeCode: string
    chargeName: string
    chargeValue: number
    chargeNotionalCostValue: number
    chargeNominalCodeId: number
    chargeTaxCodeId: number
}
export enum NotificationType {
    CompletedAssembly = 'CompletedAssembly',
    CreatedAssembly = 'CreatedAssembly',
}

export interface NotificationGroup extends BaseEntity {
    id: number
    reactedByUserId: number
    reactedByUser: User
    reactionCascade: number
    seenByUserId: number
    seenByUser: User
    seenCascade: number
    notifications: Notification[]
}

export interface Notification extends BaseEntity {
    id: number
    ownerId: number
    owner: User
    seenAt: Date
    reactedAt: Date
    notificationType: NotificationType
    comment: string
    callOffId: number
    callOff: CallOff
    salesOrderId: number
    salesOrder: SalesOrder
    purchaseOrderId: number
    purchaseOrder: PurchaseOrder
    orderConfirmationId: number
    orderConfirmation: OrderConfirmation
    assemblyId: number
    assembly: Assembly
    unauthorizedProductId: number
    unauthorizedProduct: UnauthorizedProduct
    unauthorizedBomId: number
    unauthorizedBom: UnauthorizedBom
    projectId: number
    project: Project
    groupId: number
    group: NotificationGroup
}

export interface SopChargeOrderline extends BaseEntity {
    id: number
    lineType: LineType
    salesOrderId: number
    analysisCodes: AnalysisCodes
    salesOrder: SalesOrder
    amount: number
    lineNumber: number
    code: string
    description: string
    useDescription: boolean
    taxCodeId: number
    nominalReference: string
    nominalCostCentre: string
    nominalDepartment: string
    allocatedQuantity: number
    despatchReceiptQuantity: number
    invoiceCreditQuantity: number
    postedInvoiceCreditQuantity: number
    availableForDespatch: number
    stockUnitLineQuantity: number
    stockUnitAllocatedQuantity: number
    stockUnitDespatchReceiptQuantity: number
    stockUnitInvoiceCreditQuantity: number
    stockUnitPostedInvoiceCreditQuantity: number
    stockUnitAvailableForDespatch: number
    allocationStatus: string
    despatchReceiptStatus: string
    invoiceCreditStatus: string
    sellingUnitDescription: string
    pricingUnitDescription: string
    sellingUnitPrice: number
    unitDiscountPercent: number
    unitDiscountValue: number
    costPrice: number
    discountPercentSpecified: boolean
    sellingUnitPriceOverridden: boolean
    unitDiscountOverridden: boolean
    pickingListComment: string
    despatchNoteComment: string
    showOnCustomerDocs: boolean
    showOnPickingListType: string
    confirmationIntentType: string
    hasPopOrder: boolean
    isComplete: boolean
    lineTotalValue: number
    lineTaxValue: number
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    toAllocateQuantity?: number
    stockUnitToAllocateQuantity?: number
    sellingUnitId?: number
    pricingUnitId?: number
    discountedUnitPrice?: number
    retainManualPrices?: boolean
    fulfilmentMethod: string
    markAsPreferred?: boolean
    product: Product
    productId?: number
    warehouseId?: number
    chargeId: number
    chargeCode: string
    chargeName: string
    chargeValue: number
    chargeNotionalCostValue: number
    chargeNominalCodeId: number
    chargeTaxCodeId: number
}

export interface SopCommentOrderline extends BaseEntity {
    id: number
    lineType: LineType
    salesOrderId: number
    analysisCodes: AnalysisCodes
    salesOrder: SalesOrder
    amount: number
    lineNumber: number
    code: string
    description: string
    useDescription: boolean
    taxCodeId: number
    nominalReference: string
    nominalCostCentre: string
    nominalDepartment: string
    allocatedQuantity: number
    despatchReceiptQuantity: number
    invoiceCreditQuantity: number
    postedInvoiceCreditQuantity: number
    availableForDespatch: number
    stockUnitLineQuantity: number
    stockUnitAllocatedQuantity: number
    stockUnitDespatchReceiptQuantity: number
    stockUnitInvoiceCreditQuantity: number
    stockUnitPostedInvoiceCreditQuantity: number
    stockUnitAvailableForDespatch: number
    allocationStatus: string
    despatchReceiptStatus: string
    invoiceCreditStatus: string
    sellingUnitDescription: string
    pricingUnitDescription: string
    sellingUnitPrice: number
    unitDiscountPercent: number
    unitDiscountValue: number
    costPrice: number
    discountPercentSpecified: boolean
    sellingUnitPriceOverridden: boolean
    unitDiscountOverridden: boolean
    pickingListComment: string
    despatchNoteComment: string
    showOnCustomerDocs: boolean
    showOnPickingListType: string
    confirmationIntentType: string
    hasPopOrder: boolean
    isComplete: boolean
    lineTotalValue: number
    lineTaxValue: number
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    toAllocateQuantity?: number
    stockUnitToAllocateQuantity?: number
    sellingUnitId?: number
    pricingUnitId?: number
    discountedUnitPrice?: number
    retainManualPrices?: boolean
    fulfilmentMethod: string
    markAsPreferred?: boolean
    product: Product
    productId?: number
    warehouseId?: number
}

export interface Customer extends BaseEntity {
    id: number
    projects: Project[]
    reference: string
    name: string
    shortName: string
    countryCodeId: number
    countryCode: string
    currencyId: number
    currencyName: string
    currencySymbol: string
    currencyIsoCodeId: number
    currencyIsoCode: string
    currencyIsoCodeName: string
    balance: number
    baseBalance: number
    creditLimit: number
    vatNumber: string
    defaultTaxRateId: number
    defaultTaxRateCode: number
    defaultTaxRateName: string
    defaultTaxRate: number
    defaultOrderPriority: string
    defaultNominalAccountNumber: string
    defaultNominalCostCentre: string
    defaultNominalDepartment: string
    earlySettlementDiscountPercent: number
    daysEarlySettlementDiscApplies: number
    paymentTermsInDays: number
    paymentTermsId: number
    paymentTerms: string
    priceBandId: number
    priceBandName: string
    priceBandDescription: string
    useConsolidatedBilling: boolean
    invoiceLineDiscountPercent: number
    invoiceDiscountPercent: number
    accountIsOnHold: boolean
    accountStatusType: string
    accountType: string
    valueOfCurrentOrdersInSop: number
    dateAccountDetailsLastChanged: Date
    dateOfLastTransaction: Date
    dateTimeCreated: Date
    tradingTerms: string
    creditReference: string
    creditBureauId: number
    creditBureauName: string
    creditPositionId: number
    creditPositionName: string
    averageTimeToPay: number
    termsAgreed: boolean
    useTaxCodeAsDefault: boolean
    avgTimeToPayDateLastUpdated: Date
    dunsCode: string
    telephoneAreaCode: string
    telephoneCountryCode: string
    telephoneSubscriberNumber: string
    faxAreaCode: string
    faxCountryCode: string
    faxSubscriberNumber: string
    website: string
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    customerLocationId: number
    customerLocationAddressLine1: string
    customerLocationAddressLine2: string
    customerLocationAddressLine3: string
    customerLocationAddressLine4: string
    customerLocationPostCode: string
    customerLocationCity: string
    customerLocationCounty: string
    customerLocationCountry: string
    customerLocationFullAddress: string
    customerLocationDateTimeCreated: Date
    customerLocationCountryCodeId: number
    customerLocationCountryCode: string
    customerLocationCountryCodeName: string
    customerLocationType: string
    customerLocationAddressCountryCodeId: number
    customerLocationAddressCountryCode: string
    customerLocationAddressCountryCodeName: string
    dateTimeUpdated: Date
}

export enum LineType {
    Standard = 'EnumLineTypeStandard',
    FreeText = 'EnumLineTypeFreeText',
    Charge = 'EnumLineTypeCharge',
    Comment = 'EnumLineTypeComment',
}

export enum OrderlineType {
    EnumLineTypeStandard = 'EnumLineTypeStandard',
    EnumLineTypeFreeText = 'EnumLineTypeFreeText',
    EnumLineTypeCharge = 'EnumLineTypeCharge',
    EnumLineTypeComment = 'EnumLineTypeComment',
}

export interface SalesOrder extends BaseEntity {
    id: number
    sageId: number
    projectId: number
    project: Project
    builtItemOrderlines: SopBuiltItemOrderline[]
    stockItemOrderlines: SopStockItemOrderline[]
    isCreditLimitExceeded: boolean
    paymentWithOrder: boolean
    paymentValue: number
    paymentMethodId: number
    documentNo: string
    documentDate: Date
    documentStatus: string
    customerType: string
    customerId: number
    currencyId: number
    isDraft: boolean
    exchangeRate: number
    subtotalGoodsValue: number
    subtotalChargeNetValue: number
    subtotalChargeTaxValue: number
    subtotalDiscountValue: number
    totalNetValue: number
    totalTaxValue: number
    totalGrossValue: number
    customerDocumentNo: string
    useInvoiceAddress: boolean
    settlementDiscountDays: number
    settlementDiscountPercent: number
    documentDiscountPercent: number
    documentCreatedBy: string
    requestedDeliveryDate: Date
    promisedDeliveryDate: Date
    orderPriority: string
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
    externalReference: string
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface PlotNumber {
    id: number
    projectBuiltItemId: number
    assemblyId: number | null
    value: string | null
}

export interface BomVersion extends BaseEntity {
    id: number
    version: number
    storageKey: string
    builtItem: Bom
    unauthorizedBom: UnauthorizedBom
    builtItemId: number
    activeBuiltItem?: Bom
    creatorUser?: User
    creatorUserId: number
    submitterUser?: User
    submitterUserId: number
}

export interface ExtraBomComponent extends BaseEntity {
    id: number
    amount: number
    builtItemId: number
    builtItem: Bom
    stockItemId: number
    stockItem: Product
}

export interface UnauthorizedExtraBomComponent extends BaseEntity {
    id: number
    amount: number
    unauthorizedBomId: number
    unauthorizedBom: UnauthorizedBom
    stockItemId: number
    stockItem: Product
}

/**
 * This entity represents built-items created from CTR that are yet to be created in sage
 * They are a template to create in sage until they are authorized.
 */
export interface UnauthorizedBom extends BaseEntity {
    id: number
    bomCode: string
    note: string
    authorizedDate: Date
    assemblyMinutes: number
    createSpecificVersion: number
    submittedDate: Date
    productId: number
    product: Product
    bomVersionId: number
    bomVersion: BomVersion
    unauthorizedProductId: number
    unauthorizedProduct: UnauthorizedProduct
    requestedByUser?: User
    requestedByUserId?: number
    groupId?: number
    group?: BomGroup
    requestedForProject?: Project
    instructionLink: string
    requestedForProjectId?: number
    notifications: Notification[]
    bomComponents: UnauthorizedBomComponent[]
}

export interface UnauthorizedBomComponent extends BaseEntity {
    id: number
    amount: number
    unauthorizedBomId: number
    unauthorizedBom: UnauthorizedBom
    productId: number
    product: Product
}

export enum ProductType {
    StockItem = 'StockItem',
    Bom = 'BuiltItem',
}

export interface UnauthorizedProduct extends BaseEntity {
    id: number
    bom: UnauthorizedBom
    note: string
    createdProductId: number
    createdProduct: Product
    code: string
    name: string
    description: string
    isHiu: boolean
    supplierId: number
    warehouseId: number
    warehouse: SageWarehouse
    authorizedByUser?: User
    authorizedByUserId?: number
    requestedByUser?: User
    requestedByUserId?: number
    notifications: Notification[]
    requestedForProject?: Project
    requestedForProjectId?: number
    productGroupId?: number
    productType: ProductType
    authorizationState: AuthorizationState
    productGroup: string
}

export interface TaxCode {
    code: number
    name: string
    taxRate: number
    terms: string
    termsDescription: string
    isNotionalAcquisitionTax: boolean
    id: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export interface Supplier {
    reference: string
    name: string
    shortName: string
    balance: number
    onHold: boolean
    accountStatusType: string
    statusReason: string
    currencyId: number
    exchangeRateType: string
    telephoneCountryCode: string
    telephoneAreaCode: string
    telephoneSubscriberNumber: string
    faxCountryCode: string
    faxAreaCode: string
    faxSubscriberNumber: string
    website: string
    creditLimit: number
    countryCodeId: number
    defaultTaxCodeId: number
    vatNumber: string
    accountType: string
    earlySettlementDiscountPercent: number
    earlySettlementDiscountDays: number
    paymentTermsDays: number
    paymentTermsBasis: string
    creditBureauId: number
    creditPositionId: number
    tradingTerms: string
    creditReference: string
    accountOpened: Date
    termsAgreed: boolean
    orderPriority: string
    monthsToKeepTransactions: number
    defaultNominalCodeReference: string
    defaultNominalCodeCost_centre: string
    defaultNominalCodeDepartment: string
    isSupplierPaymentsEnabled: boolean
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
    valueOfCurrentOrdersInPop: number
    factorHouseId: number
    paymentGroupId: number
    id: number
    dateTimeCreated: Date
    dateTimeUpdated: Date
}

export enum TaskDepartment {
    LOGISTICS = 'LOGISTICS',
    ASSEMBLY = 'ASSEMBLY',
    ANY = 'ANY',
}

export interface Task {
    id: number
    department: TaskDepartment
    description: string
    dateToBeCompletedBy: Date
    completedAt: Date
    minutes: number
    createdByUser?: User
    assignedUser?: User
    assignedUserId?: number
    link?: string
    typeId: number
    type?: TaskType
    warehouseId: number
    warehouse: Warehouse
    project: Project
    projectId: number
}

export interface TaskType extends BaseEntity {
    id: number
    department: TaskDepartment
    title: string
    description: string
    link?: string
    minutes: number
    createdByUserId: number
    createdByUser: User
    warehouseId: number
    warehouse: Warehouse
    tasks: Task[]
}

export interface AnalysisCodes {
    analysisCode1: string
    analysisCode2: string
    analysisCode3: string
    analysisCode4: string
    analysisCode5: string
    analysisCode6: string
    analysisCode7: string
    analysisCode8: string
    analysisCode9: string
    analysisCode10: string
    analysisCode11: string
    analysisCode12: string
    analysisCode13: string
    analysisCode14: string
    analysisCode15: string
    analysisCode16: string
    analysisCode17: string
    analysisCode18: string
    analysisCode19: string
    analysisCode20: string
}
