import { Modal } from 'antd'
import { Box, Typography } from '@mui/material'
import SmallButton from '../../../project-master/single-project-view/project-info/SmallButton'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
    closeStartAssemblyModal,
    confirmStartAssembly,
} from '../../../../store/assembly-worksheets-slice'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { setStartAssemblyView } from '../../../../store/warehouse-map-slice'
import { Urls } from '../../../../util/urls'
import withBinHoldings from '../../../../hooks/withBinHoldings'
import { BomComponentsChecklist } from '../../../modals/bin-holdings-modal/BomComponentsCheckList'
import { isFlatstation } from '../../../../util/util'
import { useMemo } from 'react'
import { WarehouseId } from '../../../../models/models'

export default function AssemblyStartModal() {
    const dispatch = useAppDispatch()
    const assembly = useAppSelector((state) => state.assemblyWorksheets.startAssemblyModal.assembly)
    const isVisible = useAppSelector(
        (state) => state.assemblyWorksheets.startAssemblyModal.isVisible
    )
    const authenticatedUser = useAppSelector((state) => state.authentication.user)
    const history = useHistory()

    const { product, state } = withBinHoldings(
        assembly?.builtItemCallOff.sopBuiltItemOrderline.builtItem.product.id,
        assembly?.callOff.warehouse.id
    )

    const builtItem = assembly?.builtItemCallOff.sopBuiltItemOrderline.builtItem
    const warehouse = assembly?.callOff.warehouse

    //whether or not it should include bin locations as an alternative to the warehouse map
    const shouldMovePallets = useMemo(() => {
        return isFlatstation(builtItem) && warehouse?.id === WarehouseId.Monument
    }, [builtItem])

    //check if createpallet is enabled
    const createPalletEnabled = builtItem?.group?.createPalletEnabled

    const confirmButtonText =
        shouldMovePallets && createPalletEnabled ? 'Move pallets' : 'Start assembly'

    if (!assembly || !authenticatedUser) {
        return null
    }

    return (
        <Modal
            title={<Typography sx={{ fontWeight: 'bold' }}>{'Start Assembly'}</Typography>}
            visible={isVisible}
            width={700}
            onCancel={() => dispatch(closeStartAssemblyModal())}
            footer={
                <Box>
                    <SmallButton
                        text={'Cancel'}
                        disabled={false}
                        onClick={() => dispatch(closeStartAssemblyModal())}
                    />
                    <SmallButton
                        color={'blue'}
                        text={confirmButtonText}
                        onClick={() => {
                            if (
                                createPalletEnabled &&
                                shouldMovePallets &&
                                assembly.startedAt !== null
                            ) {
                                dispatch(confirmStartAssembly(() => assembly))
                            } else {
                                if (!createPalletEnabled) {
                                    dispatch(
                                        confirmStartAssembly(() =>
                                            history.push(
                                                `/dashboard/worksheets/assembly/${assembly.id}`
                                            )
                                        )
                                    )
                                } else {
                                    //check for scandia
                                    if (warehouse?.id === WarehouseId.Scandia) {
                                        dispatch(
                                            confirmStartAssembly(() =>
                                                history.push(
                                                    `/dashboard/worksheets/assembly/${assembly.id}`
                                                )
                                            )
                                        )
                                    } else {
                                        //revert to existing flow
                                        history.push(Urls.WarehouseMap)
                                        dispatch(setStartAssemblyView(assembly))
                                    }
                                }
                            }
                        }}
                    />
                </Box>
            }
        >
            {product && shouldMovePallets && (
                <Box sx={{ marginRight: '50px' }}>
                    By accepting, you are assigning yourself to this assembly
                </Box>
            )}
            {product && !isFlatstation(builtItem) && (
                <Box sx={{ marginRight: '50px' }}>
                    <BomComponentsChecklist product={product} />
                </Box>
            )}
        </Modal>
    )
}
