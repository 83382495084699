import { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Select, Button, Checkbox, InputNumber, Table } from 'antd'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { styled } from '@mui/material'
import '../../tables/table-styles.css'
import { OrderedListOutlined, DeleteOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/hooks'
import { ActiveModal, closeModal } from '../../../store/products-slice'
import {
    BomGroupRelations,
    UpdateBomGroupDto,
    getBomGroupByIdRequest,
    updateBomGroupRequest,
} from '../../../api/bom-groups'
import { AssemblyField, BomGroup, WorksheetCompletionType } from '../../../models/models'
import { SearchInput } from '../../ui/SearchInput'
import { getAssemblyFieldByIdRequest, getAssemblyFieldsRequest } from '../../../api/assembly-fields'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DraggableBodyRow } from '../../project-master/sales-orders/create/OrderlinesTable'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
`

const RowElement = styled('div')``

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

enum ModalState {
    Error = 'Error',
    Ready = 'Ready',
}

export const UpdateBomGroupModal = () => {
    const { activeModal, modalEntityId } = useSelector((state: RootState) => state.products)
    const [worksheetCompletionType, setWorksheetCompletionType] = useState<WorksheetCompletionType>(
        WorksheetCompletionType.IndividualComplete
    )
    const [bomGroup, setBomGroup] = useState<BomGroup>()
    const [name, setName] = useState<string>('')
    const [assemblyTransferEnabled, setAssemblyTransferEnabled] = useState<boolean>(true)
    const [assemblyEnabled, setAssemblyEnabled] = useState<boolean>(true)
    const [createPalletEnabled, setCreatePalletEnabled] = useState<boolean>(true)
    const [disableCreatePalletEnabledDD, setDisableCreatePalletEnabledDD] = useState<boolean>(false)
    const [disableAssemblyTransferDD, setDisableAssemblyTransferDD] = useState<boolean>(false)
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const [assemblyFields, setAssemblyFields] = useState<AssemblyField[]>([])
    const dispatch = useAppDispatch()

    const resetState = () => {
        setWorksheetCompletionType(WorksheetCompletionType.IndividualComplete)
        setName('')
        setAssemblyFields([])
        setModalState(ModalState.Ready)
        setAssemblyTransferEnabled(true)
        setAssemblyEnabled(true)
        setCreatePalletEnabled(true)
    }

    useEffect(() => {
        if (activeModal === ActiveModal.BomGroupUpdate && modalEntityId) {
            const getBomGroup = async () => {
                try {
                    const response = await getBomGroupByIdRequest(modalEntityId, {
                        relations: [
                            BomGroupRelations.BomGroupAssemblyFields,
                            BomGroupRelations.BomGroupAssemblyFieldsFields,
                        ],
                    })
                    if (!response.successful) {
                        toastFailure(response.message)
                        setModalState(ModalState.Error)
                        return
                    }

                    setBomGroup(response.data)
                    setName(response.data.name)
                    setAssemblyEnabled(response.data.assemblyEnabled)
                    setAssemblyTransferEnabled(response.data.assemblyTransferEnabled)
                    setCreatePalletEnabled(response.data.createPalletEnabled)
                    setWorksheetCompletionType(response.data.worksheetCompletionType)
                    setDisableCreatePalletEnabledDD(
                        response.data.assemblyEnabled === false ? true : false
                    )
                    setDisableAssemblyTransferDD(
                        response.data.assemblyTransferEnabled === false ? true : false
                    )
                    setAssemblyFields(
                        response.data.bomGroupAssemblyFields
                            .sort((a, b) => a.columnOrder - b.columnOrder)
                            .map((af) => af.assemblyField)
                    )
                } catch (error) {
                    setModalState(ModalState.Error)
                }
            }
            getBomGroup()
        }
    }, [activeModal, modalEntityId])

    useEffect(() => {
        if (worksheetCompletionType === WorksheetCompletionType.CollectiveComplete) {
            setAssemblyFields([])
        }
    }, [worksheetCompletionType])

    const disableSubmit = useMemo(() => {
        if (!name) {
            return true
        }
        if (worksheetCompletionType === WorksheetCompletionType.IndividualComplete) {
            return assemblyFields.length === 0
        }
        return false
    }, [name, worksheetCompletionType, assemblyFields])

    const onClose = () => {
        resetState()
        dispatch(closeModal())
    }

    const onMove = (fromIndex: number, toIndex: number) => {
        const temp = [...assemblyFields]
        const element = assemblyFields[fromIndex]
        temp.splice(fromIndex, 1)
        temp.splice(toIndex, 0, element)
        setAssemblyFields(temp)
    }

    const onOk = async () => {
        if (!bomGroup) {
            return
        }
        const body: UpdateBomGroupDto = {
            name,
            assemblyEnabled,
            assemblyTransferEnabled,
            createPalletEnabled,
        }

        if (worksheetCompletionType === WorksheetCompletionType.IndividualComplete) {
            body.lines = assemblyFields.map((af) => af.id)
        }

        const response = await updateBomGroupRequest(bomGroup.id, body)
        if (!response.successful) {
            toastFailure(response.message)
            return
        }
        toastSuccess(`Updated Bom Group ${response.data.id}`)
        dispatch(closeModal(JSON.stringify(response.data)))
        resetState()
    }

    const assemblyEnabledOnChange = (value: boolean) => {
        setDisableCreatePalletEnabledDD(() => (value === false ? true : false))
        setDisableAssemblyTransferDD(() => (value === false ? true : false))
        setAssemblyTransferEnabled(value)
        setCreatePalletEnabled(value)
        setAssemblyEnabled(value)
    }

    return (
        <Modal open={activeModal === ActiveModal.BomGroupUpdate} footer={null} onCancel={onClose}>
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && (
                <Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr', // Two columns of equal width
                            gap: 2, // Space between columns
                        }}
                    >
                        <Header>
                            <OrderedListOutlined style={{ fontSize: '20px' }} />
                            <Title>Update Bom Group</Title>
                        </Header>
                        <Divider />
                        <Box sx={{ width: '100%' }}>
                            <InputRow style={{ marginBottom: '20px' }}>
                                <RowElement style={{ width: '100%' }}>
                                    <Label>Name</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </RowElement>
                                <RowElement></RowElement>
                            </InputRow>
                            <InputRow style={{ marginBottom: '20px' }}>
                                <RowElement style={{ width: '100%' }}>
                                    <Label>Type</Label>
                                    <Input value={worksheetCompletionType} readOnly={true} />
                                </RowElement>
                                <RowElement></RowElement>
                            </InputRow>
                        </Box>
                        <Box>
                            <InputRow style={{ marginBottom: '20px', width: '100%' }}>
                                <RowElement style={{ width: '100%' }}>
                                    <Label>Assembly Transfer</Label>
                                    <Select
                                        style={{ width: '100%' }}
                                        value={assemblyTransferEnabled}
                                        disabled={disableAssemblyTransferDD}
                                        onChange={(value: boolean) =>
                                            setAssemblyTransferEnabled(value)
                                        }
                                        options={[
                                            {
                                                value: true,
                                                label: 'Active',
                                            },
                                            {
                                                value: false,
                                                label: 'Inactive',
                                            },
                                        ]}
                                    />
                                </RowElement>
                                <RowElement></RowElement>
                            </InputRow>

                            <InputRow style={{ marginBottom: '20px', width: '100%' }}>
                                <RowElement style={{ width: '100%' }}>
                                    <Label>Assembly</Label>
                                    <Select
                                        style={{ width: '100%' }}
                                        value={assemblyEnabled}
                                        onChange={(value: boolean) => {
                                            assemblyEnabledOnChange(value)
                                        }}
                                        options={[
                                            {
                                                value: true,
                                                label: 'Active',
                                            },
                                            {
                                                value: false,
                                                label: 'Inactive',
                                            },
                                        ]}
                                    />
                                </RowElement>
                                <RowElement></RowElement>
                            </InputRow>

                            <InputRow style={{ marginBottom: '20px', width: '100%' }}>
                                <RowElement style={{ width: '100%' }}>
                                    <Label>Create Pallet</Label>

                                    <Select
                                        style={{ width: '100%' }}
                                        value={createPalletEnabled}
                                        disabled={disableCreatePalletEnabledDD}
                                        onChange={(value: boolean) => {
                                            setCreatePalletEnabled(value)
                                        }}
                                        options={[
                                            {
                                                value: true,
                                                label: 'Active',
                                            },
                                            {
                                                value: false,
                                                label: 'Inactive',
                                            },
                                        ]}
                                    />
                                </RowElement>
                                <RowElement></RowElement>
                            </InputRow>
                        </Box>
                    </Box>
                    {/* Second Row */}
                    <Box>
                        {(worksheetCompletionType === WorksheetCompletionType.IndividualComplete ||
                            assemblyFields.length > 0) && (
                            <>
                                <InputRow style={{ marginBottom: '20px' }}>
                                    <RowElement style={{ width: '50%' }}>
                                        <Label>Assembly Lines</Label>
                                        <SearchInput
                                            style={{ width: '100%' }}
                                            placeholder="Assembly Fields"
                                            selectedValue={undefined}
                                            excludeValues={assemblyFields.map((af) => af.id)}
                                            setSelectedValue={(id) => {
                                                if (!id) {
                                                    return
                                                }
                                                getAssemblyFieldByIdRequest(id, {}).then((r) => {
                                                    if (r.successful) {
                                                        setAssemblyFields([
                                                            ...assemblyFields,
                                                            r.data,
                                                        ])
                                                    }
                                                })
                                            }}
                                            request={(search: string) =>
                                                getAssemblyFieldsRequest({
                                                    excludeIds: assemblyFields.map((af) => af.id),
                                                    name: search,
                                                    limit: 100,
                                                }).then((r) =>
                                                    r.successful
                                                        ? r.data.entities.map((assemblyField) => ({
                                                              label: assemblyField.name,
                                                              value: assemblyField.id,
                                                          }))
                                                        : [{ label: 'None', value: 2 }]
                                                )
                                            }
                                        />
                                    </RowElement>
                                    <RowElement />
                                </InputRow>
                                <DndProvider backend={HTML5Backend}>
                                    <Table
                                        dataSource={assemblyFields}
                                        components={{
                                            body: {
                                                row: DraggableBodyRow,
                                            },
                                        }}
                                        onRow={(_, index) => {
                                            const attr = {
                                                index,
                                                onMove,
                                            }
                                            return attr as React.HTMLAttributes<any>
                                        }}
                                        columns={[
                                            {
                                                title: 'Name',
                                                dataIndex: 'name',
                                                key: 'name',
                                            },
                                            {
                                                title: 'Delete',
                                                dataIndex: 'id',
                                                key: 'id',
                                                render: (id: number) => (
                                                    <Button
                                                        shape="circle"
                                                        onClick={() => {
                                                            setAssemblyFields(
                                                                assemblyFields.filter(
                                                                    (assemblyField) =>
                                                                        assemblyField.id !== id
                                                                )
                                                            )
                                                        }}
                                                        icon={<DeleteOutlined />}
                                                    />
                                                ),
                                            },
                                        ]}
                                        pagination={false}
                                    />
                                </DndProvider>
                            </>
                        )}
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={disableSubmit} onClick={onOk}>
                            Update
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
