import { Dialog, IconButton, Divider, Paper, Button, Tab } from '@mui/material'
import { Box, styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import { CallOff } from '../../../models/models'
import { getCallOffById, SingleCallOffRelations } from '../../../api/call-off'
import { toastFailure } from '../../../util/toast'
import CTRLoader from '../../ui/loader/CTRloader'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CallOffTab from './CallOffTab'
import ProductsTab from './ProductsTab'
import AssembliesTab from './AssembliesTab'
import SalesOrderTab from './SalesOrderTab'
import PurchaseOrdersTab from './PurchaseOrdersTab'

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
})

const Content = styled(Box)({
    width: '100%',
})

const FooterContainer = styled(Box)(() => ({
    display: 'flex',
    width: '95%',
    justifyContent: 'space-between',
    margin: '10px 20px 10px 20px',
}))

const FooterButton = styled(Button)(() => ({
    margin: '10px',
}))

interface InspectCallOffModalProps {
    callOffId: number
    closeModal: () => void
    onOk?: () => void
    isOpen: boolean
}

enum State {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    Error = 'Error',
}

export type Tabs = 'CallOff' | 'Products' | 'Assemblies' | 'SalesOrder' | 'PurchaseOrders'

export default function InspectCallOffModal(props: InspectCallOffModalProps) {
    const { callOffId, closeModal, isOpen, onOk } = props
    const [activeTab, setActiveTab] = useState<Tabs>('Products')
    const [callOff, setCallOff] = useState<CallOff>()
    const [state, setState] = useState<State>(State.Loading)

    const handleTabChange = (event: React.SyntheticEvent, tabKey: Tabs) => {
        event.preventDefault()
        setActiveTab(tabKey)
    }

    const getCallOff = async () => {
        const response = await getCallOffById(callOffId, {
            relations: [
                SingleCallOffRelations.Assemblies,
                SingleCallOffRelations.AssembliesPallets,
                SingleCallOffRelations.CallOffProducts,
                SingleCallOffRelations.SalesOrder,
                SingleCallOffRelations.SalesOrderCustomer,
                SingleCallOffRelations.SalesOrderProducts,
                SingleCallOffRelations.OrderConfirmations,
                SingleCallOffRelations.CallOffProductsBomGroups,
            ],
        })

        if (response.successful) {
            setCallOff(response.data)
            setState(State.Found)
        } else if (response.status === 404) {
            setState(State.NotFound)
            toastFailure('Could not find call off')
        } else {
            setState(State.Error)
            toastFailure('Something went wrong retrieving the call off')
        }
    }

    useEffect(() => {
        getCallOff()
    }, [])

    return (
        <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
            <Paper style={{ height: '100%' }}>
                <Box
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        padding: '15px 15px 10px 23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Call off </div>
                    <IconButton aria-label="close" onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Container>
                    {state === State.Loading && (
                        <Content
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                margin: '20px',
                                width: '100%',
                                height: '400px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CTRLoader size="xl" />
                        </Content>
                    )}
                    {callOff && state === State.Found && (
                        <Content>
                            <TabContext value={activeTab}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        padding: '20px 50px 0px 50px',
                                    }}
                                >
                                    <TabList onChange={handleTabChange}>
                                        <Tab
                                            label="Call Off"
                                            value="CallOff"
                                            style={{ textTransform: 'none' }}
                                        />
                                        <Tab
                                            label="Products"
                                            value="Products"
                                            style={{ textTransform: 'none' }}
                                        />
                                        <Tab
                                            label="Assemblies"
                                            value="Assemblies"
                                            style={{ textTransform: 'none' }}
                                        />
                                        <Tab
                                            label="Purchase Orders"
                                            value="PurchaseOrders"
                                            style={{ textTransform: 'none' }}
                                        />
                                        <Tab
                                            label="Sales Order"
                                            value="SalesOrder"
                                            style={{ textTransform: 'none' }}
                                        />
                                    </TabList>
                                </Box>

                                <TabPanel value="CallOff">
                                    <CallOffTab callOff={callOff} />
                                </TabPanel>
                                <TabPanel value="Products">
                                    <ProductsTab callOff={callOff} />
                                </TabPanel>
                                <TabPanel value="Assemblies">
                                    <AssembliesTab callOff={callOff} />
                                </TabPanel>
                                <TabPanel value="PurchaseOrders">
                                    <PurchaseOrdersTab callOff={callOff} />
                                </TabPanel>
                                <TabPanel value="SalesOrder">
                                    <SalesOrderTab callOff={callOff} />
                                </TabPanel>
                            </TabContext>
                        </Content>
                    )}
                </Container>
                <FooterContainer>
                    <Box>
                        <FooterButton variant="outlined" onClick={closeModal}>
                            Back
                        </FooterButton>
                        {onOk && (
                            <FooterButton variant="outlined" onClick={() => onOk()}>
                                Done
                            </FooterButton>
                        )}
                    </Box>
                </FooterContainer>
            </Paper>
        </Dialog>
    )
}
