import { useEffect } from 'react'
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    TextField,
    Tooltip,
} from '@mui/material'
import { AddPalletModal } from './modals/AddPalletModal'
import { InspectPalletModal } from './modals/InspectPalletModal'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import { EventLogsList } from './warehouses/eventlogs/EventLogsList'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
    Area,
    getSpots,
    SearchType,
    setArea,
    setSearchText,
    setSearchType,
    ViewType,
    closeModal,
    ActiveModal,
    setRegularView,
    setIsLoading,
    updateSpots,
    updatePallets,
} from '../../store/warehouse-map-slice'
import { AssemblyTransferModal } from './modals/AssemblyTransferModal'
import { ConfirmCancelTransferModal } from './modals/ConfirmCancelTransferModal'
import { ConfirmTransferModal } from './modals/ConfirmTransferModal'
import { ConfirmDispatchModal } from './modals/ConfirmDispatchModal'
import { CancelDispatchModal } from './modals/CancelDispatchModal'
import ProductBinHoldingsModal from '../modals/bin-holdings-modal/ProductBinHoldingsModal'
import { DispatchCallOffModal } from '../worksheets/logistics/dispatch/DispatchCallOffModal'
import { useHistory } from 'react-router-dom'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { authenticatedUserView } from '../../store/authentication-slice'
import { Pallet, UserRole, WarehouseAreaSpot } from '../../models/models'
import { Urls } from '../../util/urls'
import ReplayIcon from '@mui/icons-material/Replay'
import CTRLoader from '../ui/loader/CTRloader'
import { StartAssemblyActionBar } from './view-action-bars/StartAssemblyActionBar'
import { CompleteAssemblyActionBar } from './view-action-bars/CompleteAssemblyActionBar'
import { AssemblyDispatchActionBar } from './view-action-bars/AssemblyDispatchActionBar'
import { LogisticsFloor } from './LogisticsFloor'
import { AssemblyFloor } from './AssemblyFloor'
import { UpdatePalletModal } from './modals/UpdatePalletModal'
import { DeletePalletModal } from './modals/DeletePalletModal'
import { SplitPalletModal } from './modals/SplitPalletModal'
import { styled } from '@mui/system'
import { ConfirmPalletMovementModal } from './modals/ConfirmPalletMovement'
import { ConfirmStackMovement } from './modals/ConfirmStackMovement'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CallOffDispatchActionBar } from './view-action-bars/CallOffDispatchActionBar'
import { RegularActionBar } from './view-action-bars/RegularActionBar'
import { TransferActionBar } from './view-action-bars/TransferActionBar'
import { io } from 'socket.io-client'
import { BACKEND_WEBSOCKET } from '../../configurations/backend-configuration'
import { SportsCricketOutlined } from '@mui/icons-material'

const HeaderContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5px',
    height: '60px',
    width: '100%',
})

const SearchContainer = styled(Box)({
    marginTop: '10px',
})

const FloorChangeContainer = styled(Box)({
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
})

const ActionBarContainer = styled(Box)({
    marginTop: '-10px',
})

export default function WarehouseMap() {
    const {
        area,
        searchText,
        searchType,
        disableSearch,
        viewType,
        activeModal,
        modalPalletId,
        modalProductId,
        modalSpotId,
        modalCallOffId,
        isLoading,

        isTablet,
    } = useAppSelector((state) => state.warehouseMap)
    const user = useAppSelector(authenticatedUserView)

    const dispatch = useAppDispatch()
    const history = useHistory()

    const isUserAllowedFloor1Access = !!user && ![UserRole.Assembler].includes(user.role)

    const getUserAvailableTabs = (): Area[] => {
        if (isUserAllowedFloor1Access) {
            return [Area.FirstFloor, Area.SecondFloor, Area.EventLog]
        }
        return [Area.SecondFloor]
    }

    useEffect(() => {
        const socket = io(BACKEND_WEBSOCKET, {
            path: '/api/warehouse-map-ws',
            transports: ['websocket'],
            autoConnect: true,
            reconnection: true,
        })

        //use this to update warehouse-map instead of request responses
        socket.on('spots', function (data) {
            const spots = JSON.parse(data) as WarehouseAreaSpot[]
            dispatch(updateSpots(spots))
        })

        //use this to update warehouse-map instead of request responses
        socket.on('pallets', function (data) {
            const pallets = JSON.parse(data) as Pallet[]
            dispatch(updatePallets(pallets))
        })

        return () => {
            socket.removeAllListeners()
            socket.close()
        }
    }, [])

    const Tabs: { [key in Area]: JSX.Element } = {
        [Area.FirstFloor]: (
            <Tab
                key={Area.FirstFloor}
                label="Floor 1"
                onClick={() => dispatch(setArea(Area.FirstFloor))}
                style={{ textTransform: 'none' }}
                value="1"
                disabled={user?.role === UserRole.Assembler}
            />
        ),

        [Area.SecondFloor]: (
            <Tab
                key={Area.SecondFloor}
                label="Floor 2"
                style={{ textTransform: 'none' }}
                onClick={() => dispatch(setArea(Area.SecondFloor))}
                value="2"
            />
        ),
        [Area.EventLog]: (
            <Tab
                key={Area.EventLog}
                label="Event Log"
                style={{ textTransform: 'none' }}
                onClick={() => dispatch(setArea(Area.EventLog))}
                value="3"
            />
        ),
    }

    const getTabs = () => {
        return getUserAvailableTabs().map((tabKey) => Tabs[tabKey])
    }

    const handleAreaChange = (event: React.SyntheticEvent, area: Area, callback?: () => void) => {
        event.preventDefault()
        if (!isUserAllowedFloor1Access) {
            dispatch(setArea(Area.SecondFloor))
            return
        }
        dispatch(setArea(area))
        !!callback &&
            setTimeout(() => {
                callback()
            }, 0)
    }

    const fetchSpots = () => {
        dispatch(getSpots())
    }

    useEffect(() => {
        if (!isUserAllowedFloor1Access) {
            dispatch(setArea(Area.SecondFloor))
        }
    }, [area])

    useEffect(() => {
        dispatch(setIsLoading(true))
        fetchSpots()
        if (!isUserAllowedFloor1Access) {
            dispatch(setArea(Area.SecondFloor))
            return
        }

        //consider placing in MonumentFloorComponents
        const MINUTE_MS = 60000
        const interval = setInterval(() => {
            fetchSpots()
        }, MINUTE_MS)

        return () => clearInterval(interval)
    }, [])

    return (
        <>
            {isLoading && <CTRLoader />}
            <Box
                style={{
                    position: 'relative',
                    width: '100%',
                    visibility: !isLoading ? 'visible' : 'hidden',
                }}
            >
                <Box style={{ margin: '10px 20px' }}>
                    <TabContext value={area}>
                        <DndProvider
                            backend={isTablet ? TouchBackend : HTML5Backend}
                            options={{ enableMouseEvents: true }}
                        >
                            {/**This is the component responsible for the zooming, dragging and auto-placing in the middle. */}
                            <TransformWrapper
                                doubleClick={{ disabled: true }}
                                maxScale={2.2}
                                minScale={0.7}
                                initialScale={0.7}
                                panning={{
                                    disabled: false,
                                }}
                            >
                                {({ resetTransform, centerView, ...rest }) => (
                                    <>
                                        <HeaderContainer>
                                            <Tooltip
                                                title="Reload the warehouse map"
                                                placement="top"
                                            >
                                                <Button
                                                    variant={'contained'}
                                                    color={'secondary'}
                                                    onClick={() => {
                                                        dispatch(setIsLoading(true))
                                                        fetchSpots()
                                                    }}
                                                    style={{
                                                        margin: '10px 23px',
                                                        padding: '6px 0px',
                                                    }}
                                                >
                                                    <ReplayIcon />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Zoom to default" placement="top">
                                                <Button
                                                    variant={'contained'}
                                                    color={'secondary'}
                                                    onClick={() => {
                                                        resetTransform()
                                                    }}
                                                    style={{
                                                        margin: '10px 23px',
                                                        padding: '6px 0px',
                                                    }}
                                                >
                                                    <AspectRatioIcon />
                                                </Button>
                                            </Tooltip>
                                            <SearchContainer>
                                                <FormControl size="small">
                                                    <TextField
                                                        sx={{
                                                            '& fieldset': {
                                                                borderRadius: '10px 0 0 10px',
                                                            },
                                                        }}
                                                        label="Search..."
                                                        color={'primary'}
                                                        variant={'outlined'}
                                                        disabled={disableSearch}
                                                        type={
                                                            searchType === SearchType.PalletId
                                                                ? 'number'
                                                                : 'text'
                                                        }
                                                        value={searchText}
                                                        onChange={(e) => {
                                                            dispatch(setSearchText(e.target.value))
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    sx={{ minWidth: 160 }}
                                                    disabled={disableSearch}
                                                >
                                                    <InputLabel>Search by</InputLabel>
                                                    <Select
                                                        label="Search by"
                                                        sx={{
                                                            '& fieldset': {
                                                                borderRadius: '0 10px 10px 0',
                                                            },
                                                        }}
                                                        value={searchType}
                                                        style={{
                                                            borderRadius: 0,
                                                            borderRight: 'none',
                                                        }}
                                                        onChange={(e) => {
                                                            dispatch(
                                                                setSearchType(
                                                                    e.target.value as SearchType
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        <MenuItem value={SearchType.ProjectName}>
                                                            Project name
                                                        </MenuItem>
                                                        <MenuItem value={SearchType.OcCode}>
                                                            Order Confirmation Code
                                                        </MenuItem>
                                                        <MenuItem value={SearchType.ProductCode}>
                                                            Product code
                                                        </MenuItem>
                                                        <MenuItem value={SearchType.PalletId}>
                                                            Pallet id
                                                        </MenuItem>
                                                        <MenuItem value={SearchType.AssemblyId}>
                                                            Assembly Id
                                                        </MenuItem>
                                                        <MenuItem value={SearchType.CallOffId}>
                                                            Call Off Id
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </SearchContainer>

                                            <FloorChangeContainer>
                                                <Box
                                                    style={{
                                                        float: 'right',
                                                        marginBottom: '20px',
                                                    }}
                                                >
                                                    {(viewType === ViewType.Regular ||
                                                        viewType === ViewType.CallOffDispatch ||
                                                        viewType === ViewType.AssemblyDispatch) && (
                                                        <Box
                                                            style={{
                                                                marginRight: 0,
                                                            }}
                                                        >
                                                            <TabList
                                                                onChange={(
                                                                    event: React.SyntheticEvent,
                                                                    area: Area
                                                                ) =>
                                                                    handleAreaChange(
                                                                        event,
                                                                        area,
                                                                        resetTransform
                                                                    )
                                                                }
                                                            >
                                                                {getTabs()}
                                                            </TabList>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </FloorChangeContainer>
                                        </HeaderContainer>
                                        <TransformComponent
                                            wrapperStyle={{
                                                maxWidth: '100%',
                                                width: '100%',
                                                padding: '0px',
                                                margin: '0px',
                                                height: '80%',
                                            }}
                                        >
                                            <TabPanel value="1">
                                                {isUserAllowedFloor1Access && <LogisticsFloor />}
                                            </TabPanel>
                                            <TabPanel value="2">
                                                <AssemblyFloor />
                                            </TabPanel>
                                        </TransformComponent>
                                        <TabPanel value="3">
                                            <EventLogsList />
                                        </TabPanel>
                                    </>
                                )}
                            </TransformWrapper>
                        </DndProvider>
                    </TabContext>
                    <ActionBarContainer>
                        {viewType === ViewType.Transfer && <TransferActionBar />}
                        {viewType === ViewType.AssemblyStart && <StartAssemblyActionBar />}
                        {viewType === ViewType.AssemblyComplete && <CompleteAssemblyActionBar />}
                        {viewType === ViewType.AssemblyDispatch && <AssemblyDispatchActionBar />}
                        {viewType === ViewType.CallOffDispatch && <CallOffDispatchActionBar />}
                        {viewType === ViewType.Regular && <RegularActionBar />}
                    </ActionBarContainer>
                </Box>

                {/***  MODALS ***/}

                {activeModal === ActiveModal.InspectBinHoldings && modalProductId && (
                    <ProductBinHoldingsModal
                        isOpen={activeModal === ActiveModal.InspectBinHoldings}
                        closeModal={() => {
                            dispatch(closeModal())
                        }}
                        productId={modalProductId}
                        warehouseId={1}
                    />
                )}

                {activeModal === ActiveModal.ConfirmCallOffDispatch && modalCallOffId && (
                    <DispatchCallOffModal
                        isOpen={activeModal === ActiveModal.ConfirmCallOffDispatch}
                        closeModal={() => {
                            dispatch(closeModal())
                        }}
                        onOk={() => {
                            history.push(Urls.WorksheetsLogistics)
                            dispatch(setRegularView())
                            dispatch(closeModal())
                        }}
                        callOffId={modalCallOffId}
                    />
                )}

                <ConfirmCancelTransferModal
                    isOpen={activeModal === ActiveModal.ConfirmCancelTransfer}
                    onClose={() => dispatch(closeModal())}
                />

                <ConfirmDispatchModal
                    isOpen={activeModal === ActiveModal.ConfirmAssemblyDispatch}
                    onClose={() => dispatch(closeModal())}
                />

                <CancelDispatchModal
                    isOpen={activeModal === ActiveModal.CancelDispatch}
                    onClose={() => dispatch(closeModal())}
                />

                <ConfirmTransferModal
                    isOpen={activeModal === ActiveModal.ConfirmTransfer}
                    onClose={() => dispatch(closeModal())}
                />

                <AddPalletModal
                    isOpen={activeModal === ActiveModal.AddPallet}
                    onClose={() => dispatch(closeModal())}
                    areaId={Number(area)}
                />

                <AddPalletModal
                    isOpen={activeModal === ActiveModal.AddPallet}
                    onClose={() => dispatch(closeModal())}
                    areaId={Number(area)}
                />

                {modalPalletId && (
                    <InspectPalletModal
                        isOpen={activeModal === ActiveModal.InspectPallet}
                        onClose={() => dispatch(closeModal())}
                        palletId={modalPalletId}
                    />
                )}
                {modalPalletId && (
                    <UpdatePalletModal
                        isOpen={activeModal === ActiveModal.UpdatePallet}
                        onClose={() => dispatch(closeModal())}
                        palletId={modalPalletId}
                    />
                )}
                {modalPalletId && (
                    <DeletePalletModal
                        isOpen={activeModal === ActiveModal.DeletePallet}
                        onClose={() => dispatch(closeModal())}
                        palletId={modalPalletId}
                    />
                )}
                {modalPalletId && (
                    <SplitPalletModal
                        isOpen={activeModal === ActiveModal.SplitPallet}
                        onClose={() => dispatch(closeModal())}
                        palletId={modalPalletId}
                    />
                )}

                {modalPalletId && (
                    <AssemblyTransferModal
                        isOpen={activeModal === ActiveModal.AssemblyTransfer}
                        onClose={() => dispatch(closeModal())}
                        palletId={modalPalletId}
                    />
                )}
                {modalSpotId && activeModal === ActiveModal.ConfirmStackMovement && (
                    <ConfirmStackMovement
                        isOpen={activeModal === ActiveModal.ConfirmStackMovement}
                        onClose={() => dispatch(closeModal())}
                        spotId={modalSpotId}
                    />
                )}
                {modalSpotId && activeModal === ActiveModal.ConfirmPalletMovement && (
                    <ConfirmPalletMovementModal
                        isOpen={activeModal === ActiveModal.ConfirmPalletMovement}
                        onClose={() => dispatch(closeModal())}
                        spotId={modalSpotId}
                    />
                )}
            </Box>
        </>
    )
}
