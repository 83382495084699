import { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Divider, Input, Modal, Button, Select } from 'antd'
import { styled } from '@mui/material'
import '../../tables/table-styles.css'
import { OrderedListOutlined } from '@ant-design/icons'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { ActiveModal } from '../../../store/products-slice'
import { Project, Supplier, WarehouseId } from '../../../models/models'
import { SearchInput, sortLabelAlphaNumerically } from '../../ui/SearchInput'
import {
    ProductGroup,
    getProductGroupByIdRequest,
    getProductGroupsRequest,
    getSuppliers,
} from '../../../api/stock-item'
import { createUnauthorizedStockItemRequest } from '../../../api/unauthorized-product'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { getProjectByIdRequest, getProjectsRequest } from '../../../api/projects/projects'
import { getManySageWarehouses } from '../../../api/sage-warehouse'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface CreateUnauthorizedStockItemModalProps {
    isOpen: boolean
    onClose: () => void
}

enum ModalState {
    Loading = 'Loading',
    Ready = 'Ready',
    Error = 'Error',
}

const nameMaxLength = 60

export const CreateProductModal = ({ isOpen, onClose }: CreateUnauthorizedStockItemModalProps) => {
    const { activeModal } = useSelector((state: RootState) => state.products)
    const [modalState, setModalState] = useState<ModalState>(ModalState.Ready)
    const [code, setCode] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [note, setNote] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [selectedProductGroup, setSelectedProductGroup] = useState<ProductGroup>()
    const [selectedSupplierId, setSelectedSupplierId] = useState<number>()
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<number>()
    const [selectedProject, setSelectedProject] = useState<Project>()

    const resetState = () => {
        setCode('')
        setDescription('')
        setName('')
        setSelectedProductGroup(undefined)
        setSelectedSupplierId(undefined)
    }

    const onOk = async () => {
        if (
            code.length === 0 ||
            description.length === 0 ||
            name.length === 0 ||
            !selectedProductGroup ||
            !selectedSupplierId ||
            !selectedWarehouseId
        ) {
            return
        }
        const response = await createUnauthorizedStockItemRequest({
            code,
            name,
            description,
            supplierId: selectedSupplierId,
            note,
            warehouseId: selectedWarehouseId,
            requestedForProjectId: selectedProject?.id,
            productGroupId: selectedProductGroup.id,
        })
        if (response.successful) {
            toastSuccess('Successfully created the unauthorized product')
            onClose()
        } else {
            toastFailure(response.message)
        }
    }

    const isSubmitDisabled = () => {
        return (
            code.length === 0 ||
            description.length === 0 ||
            name.length === 0 ||
            name.length > nameMaxLength ||
            !selectedProductGroup ||
            !selectedSupplierId ||
            !selectedWarehouseId
        )
    }

    useEffect(() => {
        if (!isOpen) {
            resetState()
        }
    }, [isOpen])

    return (
        <Modal
            open={activeModal === ActiveModal.ProductCreate}
            footer={null}
            onCancel={onClose}
            width={900}
        >
            {modalState === ModalState.Error && <Box>Something went wrong</Box>}
            {modalState === ModalState.Ready && (
                <Box>
                    <Header>
                        <OrderedListOutlined style={{ fontSize: '20px' }} />
                        <Title>Create Product</Title>
                    </Header>
                    <Divider />
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Code</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                status={name.length > nameMaxLength ? 'error' : ''}
                                showCount
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Product Group</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Product Groups"
                                selectedValue={selectedProductGroup?.id}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getProductGroupByIdRequest(id).then((r) => {
                                        if (r.successful) {
                                            setSelectedProductGroup(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getProductGroupsRequest(search).then((r) =>
                                        r.successful
                                            ? r.data.map((productGroup) => ({
                                                  label: productGroup.code,
                                                  value: productGroup.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Supplier</Label>
                            <SearchInput
                                optionSorter={sortLabelAlphaNumerically}
                                style={{ width: '100%' }}
                                showLoading={true}
                                placeholder="Suppliers"
                                selectedValue={selectedSupplierId}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    setSelectedSupplierId(id)
                                }}
                                request={(search: string) =>
                                    getSuppliers(search).then((r) =>
                                        r.successful
                                            ? r.data.map((supplier) => ({
                                                  label: supplier.name,
                                                  value: supplier.id,
                                              }))
                                            : [{ label: 'None', value: undefined }]
                                    )
                                }
                            />
                        </RowElement>
                    </InputRow>

                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Description</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                optionSorter={sortLabelAlphaNumerically}
                                showLoading={true}
                                placeholder="Warehouse"
                                disableSearch={true}
                                selectedValue={selectedWarehouseId}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    setSelectedWarehouseId(id)
                                }}
                                request={(search: string) =>
                                    getManySageWarehouses().then((r) =>
                                        r.successful
                                            ? r.data.map((warehouse) => ({
                                                  label: warehouse.sageName,
                                                  value: warehouse.sageWarehouseId,
                                              }))
                                            : [{ label: 'None', value: undefined }]
                                    )
                                }
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Note</Label>
                            <Input.TextArea
                                style={{ width: '100%', minHeight: 120 }}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Project</Label>
                            <SearchInput
                                style={{ width: '100%' }}
                                placeholder="Project"
                                optionSorter={sortLabelAlphaNumerically}
                                selectedValue={selectedProject?.id}
                                setSelectedValue={(id) => {
                                    if (!id) {
                                        return
                                    }
                                    getProjectByIdRequest(id).then((r) => {
                                        if (r.successful) {
                                            setSelectedProject(r.data)
                                        }
                                    })
                                }}
                                request={(search: string) =>
                                    getProjectsRequest({ like: search }).then((r) =>
                                        r.successful
                                            ? r.data.projects.map((project) => ({
                                                  label: project.name,
                                                  value: project.id,
                                              }))
                                            : [{ label: 'None', value: 2 }]
                                    )
                                }
                            />
                        </RowElement>
                    </InputRow>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px' }}>
                        <Button type={'primary'} disabled={isSubmitDisabled()} onClick={onOk}>
                            Create
                        </Button>
                    </Box>
                </Box>
            )}
        </Modal>
    )
}
