import { Modal, Steps, Input, Checkbox } from 'antd'
import { CallOff, BuiltItemCallOff, Schedule } from '../../../../../../models/models'
import { useEffect, useMemo, useState } from 'react'
import moment, { Moment } from 'moment'
import { Box } from '@mui/system'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
    callOffBuiltItemsCompletedDetails,
    numberInputOnWheelPreventChange,
} from '../../../../../../util/util'
import { createAssemblies, AssemblyToCreate } from '../../../../../../api/assemblies'
import { toastFailure, toastSuccess } from '../../../../../../util/toast'
import { getProductionSchedules } from '../../../../../../api/production-schedule'
import { IconButton, styled } from '@mui/material'
import DateCell from './create-assembly/DateCell'
import './create-assembly/calendar.css'
import DeleteIcon from '@mui/icons-material/Delete'
import TextArea from 'antd/lib/input/TextArea'
import {
    getScheduleAvailableMinutes,
    getTotalMinutes,
} from '../../../../../../util/production-schedule'
import Calendar from '../../../../../ui/calendar/Calendar'
import { SingleCallOffRelations, getCallOffById } from '../../../../../../api/call-off'
interface ApproveCallOffModalProps {
    callOffId: number
    onClose: () => void
}

enum ModalState {
    Product = 'PRODUCT',
    Days = 'DAYS',
    Confirm = 'CONFIRM',
}

interface Production {
    amount: number
    dateToBeCompletedBy: Moment
    comment: string
}

const DaysHeaderContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
})

const ProductAllocationInformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    paddingLeft: '50px',
})

const ProductionScheduleInformationContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    paddingLeft: '50px',
})

export default function CreateAssemblyModal({ callOffId, onClose }: ApproveCallOffModalProps) {
    const [selectedDate, setSelectedDate] = useState<Moment>(moment().add(1, 'day'))
    const [modalState, setModalState] = useState<ModalState>(ModalState.Product)
    const [chosenBuiltItemCallOff, setChosenBuiltItemCallOff] = useState<BuiltItemCallOff>()
    const [totalAmountAvailableForSplits, setTotalAmountAvailableForSplits] = useState<string>('')
    const [assembliesToCreate, setAssembliesToCreate] = useState<Production[]>([])
    const [assemblyAmount, setAssemblyAmount] = useState<string>('')
    const [productionSchedule, setProductionSchedule] = useState<Schedule[]>([])
    const [callOff, setCallOff] = useState<CallOff>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchCallOff = async () => {
            const response = await getCallOffById(callOffId, {
                relations: [
                    SingleCallOffRelations.Assemblies,
                    SingleCallOffRelations.CallOffProducts,
                    SingleCallOffRelations.CallOffProductsBomGroups,
                ],
            })
            if (response.successful) {
                setCallOff(response.data)
            } else {
                toastFailure(response.message)
            }
        }
        fetchCallOff()
    }, [callOffId])

    const fetchProductionSchedule = async () => {
        if (!callOff) {
            return
        }
        const response = await getProductionSchedules({
            from: moment(selectedDate).startOf('month').startOf('day').utcOffset(0, true).toDate(),
            to: moment(selectedDate)
                .endOf('month')
                .add(9, 'days')
                .endOf('day')
                .utcOffset(0, true)
                .toDate(),
            warehouseId: callOff.warehouseId,
        })
        if (response.successful) {
            setProductionSchedule(response.data)
        } else {
            toastFailure(response.message)
        }
    }
    useEffect(() => {
        setTotalAmountAvailableForSplits('')
    }, [chosenBuiltItemCallOff])

    useEffect(() => {
        fetchProductionSchedule()
    }, [selectedDate])

    const onOk = async () => {
        if (chosenBuiltItemCallOff === undefined || isLoading || !callOff) {
            return
        }
        setIsLoading(true)
        const response = await createAssemblies({
            callOffId: callOff.id,
            builtItemCallOffId: chosenBuiltItemCallOff.id,
            assemblies: assembliesToCreate.map(
                (assembly: Production, index: number): AssemblyToCreate => ({
                    dateToBeCompletedBy: assembly.dateToBeCompletedBy.utcOffset(0, true).toDate(),
                    amount: assembly.amount,
                    assemblyComment: assembly.comment,
                })
            ),
        })
        setIsLoading(false)
        if (!response.successful) {
            toastFailure(response.message)
            return
        }
        toastSuccess('Created assemblies')
        onClose()
    }

    const callOffbuiltItemDetails = useMemo(() => {
        return callOff ? callOffBuiltItemsCompletedDetails(callOff) : {}
    }, [callOff])

    const setAmountAvailableForSplits = (value: string) => {
        if (chosenBuiltItemCallOff) {
            const { assemblyAllocatedAmount, callOffAmount, completedAmount } =
                callOffbuiltItemDetails[chosenBuiltItemCallOff.id]
            const available = callOffAmount - assemblyAllocatedAmount
            if (Number(value) >= available) {
                setTotalAmountAvailableForSplits(available.toString())
            } else {
                setTotalAmountAvailableForSplits(value)
            }
        } else {
            setTotalAmountAvailableForSplits('')
        }
    }

    const setAmountToSingleSplit = (value: string) => {
        const available =
            Number(totalAmountAvailableForSplits) -
            assembliesToCreate.reduce((acc, curr) => acc + curr.amount, 0)
        if (Number(value) >= available) {
            setAssemblyAmount(available.toString())
        } else {
            setAssemblyAmount(value)
        }
    }

    const getSchedule = (date: Moment) =>
        productionSchedule.find((schedule) => date.isSame(moment.utc(schedule.day), 'day'))

    const selectedSchedule = getSchedule(selectedDate)

    const addAssemblyToCreate = () => {
        if (!chosenBuiltItemCallOff) {
            return
        }
        setAssembliesToCreate([
            ...assembliesToCreate,
            {
                amount: Number(assemblyAmount),
                dateToBeCompletedBy: selectedDate,
                comment: '',
            },
        ])
        setAssemblyAmount('')
    }

    const ProductsFooter = () => {
        return (
            <Box>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={
                        chosenBuiltItemCallOff === undefined ||
                        totalAmountAvailableForSplits.length === 0 ||
                        totalAmountAvailableForSplits === '0'
                    }
                    onClick={() => setModalState(ModalState.Days)}
                >
                    Continue
                </Button>
            </Box>
        )
    }

    const DaysFooter = () => {
        return (
            <Box>
                <Button onClick={() => setModalState(ModalState.Product)}>Back</Button>
                <Button
                    disabled={
                        Number(totalAmountAvailableForSplits) -
                            assembliesToCreate.reduce((acc, curr) => acc + curr.amount, 0) !==
                        0
                    }
                    onClick={() => {
                        setModalState(ModalState.Confirm)
                    }}
                >
                    Continue
                </Button>
            </Box>
        )
    }

    const ConfirmFooter = () => {
        return (
            <Box>
                <Button onClick={() => setModalState(ModalState.Days)}>Back</Button>
                <Button disabled={isLoading} onClick={onOk}>
                    Submit
                </Button>
            </Box>
        )
    }

    const currentStep = () => {
        switch (modalState) {
            case ModalState.Product:
                return 0
            case ModalState.Days:
                return 1
            case ModalState.Confirm:
                return 2
        }
    }

    const isDateDisabled = (date: Moment) => {
        return (
            date.weekday() === 5 ||
            date.weekday() === 6 ||
            moment().subtract(1, 'day').isSameOrAfter(date, 'day')
        )
    }

    const onChangeHandler = (date: Moment) => {
        setSelectedDate(date)
    }

    const getCurrentDayScheduled = (): Production | undefined => {
        return assembliesToCreate.find((assembly) =>
            assembly.dateToBeCompletedBy.isSame(selectedDate, 'day')
        )
    }

    const getAssemblyTotalMinutes = (): number => {
        const assembly = getCurrentDayScheduled()
        if (!chosenBuiltItemCallOff || !assembly) {
            return 0
        }
        return (
            assembly.amount * chosenBuiltItemCallOff.sopBuiltItemOrderline.builtItem.assemblyMinutes
        )
    }

    const hideAssemblyInactiveProducts = (builtItemCalloff: BuiltItemCallOff): boolean => {
        if (
            builtItemCalloff.sopBuiltItemOrderline.builtItem.group &&
            builtItemCalloff.sopBuiltItemOrderline.builtItem.group.assemblyEnabled === false
        ) {
            return false
        } else {
            return true
        }
    }

    return (
        <Modal
            title="Schedule Assemblies"
            open={true}
            onCancel={onClose}
            onOk={onOk}
            width={1400}
            okButtonProps={{
                disabled: true,
            }}
            footer={
                <>
                    {modalState === ModalState.Product && <ProductsFooter />}
                    {modalState === ModalState.Days && <DaysFooter />}
                    {modalState === ModalState.Confirm && <ConfirmFooter />}
                </>
            }
        >
            <div>
                <Steps size="small" current={currentStep()} style={{ marginBottom: '40px' }}>
                    <Steps.Step title="Products" />
                    <Steps.Step title="Scheduling" />
                    <Steps.Step title="Confirm" />
                </Steps>
                {modalState === ModalState.Product && callOff && (
                    <>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Built Item BOM</TableCell>
                                    <TableCell>Amount on project</TableCell>
                                    <TableCell>Amount ordered on call off</TableCell>
                                    <TableCell>Amount working on/finished on call off</TableCell>
                                    <TableCell>Assembly minutes per unit</TableCell>
                                    <TableCell>Choose</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {callOff.builtItemCallOffs.map(
                                    (builtItemCalloff) =>
                                        hideAssemblyInactiveProducts(builtItemCalloff) && (
                                            <TableRow key={builtItemCalloff.id}>
                                                <TableCell>
                                                    {
                                                        builtItemCalloff.sopBuiltItemOrderline
                                                            ?.builtItem?.bomCode
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {builtItemCalloff.sopBuiltItemOrderline?.amount}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        callOffbuiltItemDetails[builtItemCalloff.id]
                                                            .callOffAmount
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        callOffbuiltItemDetails[builtItemCalloff.id]
                                                            .assemblyAllocatedAmount
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        builtItemCalloff.sopBuiltItemOrderline
                                                            ?.builtItem?.assemblyMinutes
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        value={builtItemCalloff.id}
                                                        checked={
                                                            builtItemCalloff.id ===
                                                            chosenBuiltItemCallOff?.id
                                                        }
                                                        onChange={() =>
                                                            setChosenBuiltItemCallOff(
                                                                builtItemCalloff.id ===
                                                                    chosenBuiltItemCallOff?.id
                                                                    ? undefined
                                                                    : builtItemCalloff
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                )}
                            </TableBody>
                        </Table>
                        <Box style={{ margin: '20px 0px 10px 0px' }}>
                            <Input
                                autoComplete="off"
                                size="large"
                                type="number"
                                placeholder="Amount to split"
                                disabled={chosenBuiltItemCallOff === undefined}
                                value={totalAmountAvailableForSplits}
                                onChange={(e) => setAmountAvailableForSplits(e.target.value)}
                                onWheel={numberInputOnWheelPreventChange}
                            />
                        </Box>
                    </>
                )}
                {modalState === ModalState.Days && callOff && (
                    <>
                        <DaysHeaderContainer>
                            <ProductionScheduleInformationContainer>
                                {selectedSchedule !== undefined && (
                                    <>
                                        <h4>
                                            Assemblies on date:
                                            <b>{` ${selectedSchedule.assemblies.length}`}</b>
                                        </h4>
                                        <h4>
                                            Available minutes on date:
                                            <b>
                                                {` ${getScheduleAvailableMinutes(
                                                    selectedSchedule
                                                )} /
                                            ${getTotalMinutes(
                                                moment(selectedSchedule.day),
                                                selectedSchedule.assemblers
                                            )}`}
                                            </b>
                                        </h4>
                                        <h4>
                                            After assembly allocation
                                            <b>
                                                {` ${
                                                    getScheduleAvailableMinutes(selectedSchedule) -
                                                    getAssemblyTotalMinutes()
                                                }`}
                                            </b>
                                        </h4>
                                    </>
                                )}
                            </ProductionScheduleInformationContainer>
                            <ProductAllocationInformationContainer>
                                <h4>
                                    Product:{' '}
                                    <b>
                                        {
                                            chosenBuiltItemCallOff?.sopBuiltItemOrderline?.builtItem
                                                ?.bomCode
                                        }
                                    </b>
                                </h4>
                                <h4>
                                    Allocated:
                                    <b>
                                        {` ${
                                            Number(totalAmountAvailableForSplits) -
                                            assembliesToCreate.reduce(
                                                (acc, cur) => acc - cur.amount,
                                                Number(totalAmountAvailableForSplits)
                                            )
                                        } / ${totalAmountAvailableForSplits}`}
                                    </b>
                                </h4>
                            </ProductAllocationInformationContainer>
                        </DaysHeaderContainer>
                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                            <Box style={{ width: '40%', margin: '20' }}>
                                <Calendar
                                    disabledDate={(date: Moment) => {
                                        return isDateDisabled(date)
                                    }}
                                    mode="month"
                                    dateFullCellRender={(moment) => (
                                        <DateCell
                                            date={moment}
                                            selectedDate={selectedDate}
                                            schedule={getSchedule(moment)}
                                            warehouse={callOff.warehouse}
                                        />
                                    )}
                                    fullscreen={false}
                                    value={selectedDate}
                                    onSelect={onChangeHandler}
                                />
                                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Input
                                        autoComplete="off"
                                        size="large"
                                        type="number"
                                        disabled={getCurrentDayScheduled() !== undefined}
                                        placeholder="Amount for split"
                                        value={assemblyAmount}
                                        onChange={(e) => setAmountToSingleSplit(e.target.value)}
                                        onWheel={numberInputOnWheelPreventChange}
                                    />
                                    <Button
                                        disabled={
                                            isDateDisabled(selectedDate) ||
                                            assemblyAmount.length === 0
                                        }
                                        onClick={() => addAssemblyToCreate()}
                                    >
                                        Create
                                    </Button>
                                </Box>
                            </Box>
                            <Box style={{ width: '60%', margin: '20px' }}>
                                <h3>Assemblies</h3>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Minutes</TableCell>
                                            <TableCell>Assembly Comments</TableCell>
                                            <TableCell>Remove</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {assembliesToCreate.map((assembly, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {assembly.dateToBeCompletedBy.format(
                                                        'DD/MM/YYYY'
                                                    )}
                                                </TableCell>
                                                <TableCell>{assembly.amount}</TableCell>
                                                <TableCell>
                                                    {/** Fricking typescript ... */}
                                                    {chosenBuiltItemCallOff?.sopBuiltItemOrderline
                                                        ?.builtItem?.assemblyMinutes
                                                        ? chosenBuiltItemCallOff
                                                              ?.sopBuiltItemOrderline?.builtItem
                                                              ?.assemblyMinutes * assembly.amount
                                                        : 'undefined'}
                                                </TableCell>
                                                <TableCell>
                                                    <TextArea
                                                        rows={2}
                                                        maxLength={255}
                                                        onChange={(e) => {
                                                            const copy = [...assembliesToCreate]
                                                            copy[i].comment = e.target.value
                                                            setAssembliesToCreate(copy)
                                                        }}
                                                        value={assembliesToCreate[i].comment}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => {
                                                            const copy = [...assembliesToCreate]
                                                            copy.splice(i, 1)
                                                            setAssembliesToCreate(copy)
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </>
                )}
                {modalState === ModalState.Confirm && (
                    <>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Minutes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assembliesToCreate.map((assembly, i) => (
                                    <TableRow
                                        key={i}
                                        style={{
                                            cursor: 'initial',
                                            background: 'initial',
                                            height: 'initial',
                                        }}
                                    >
                                        <TableCell>
                                            {assembly.dateToBeCompletedBy.format('DD/MM/YYYY')}
                                        </TableCell>
                                        <TableCell>{assembly.amount}</TableCell>
                                        <TableCell>
                                            {chosenBuiltItemCallOff?.sopBuiltItemOrderline
                                                ?.builtItem?.assemblyMinutes
                                                ? chosenBuiltItemCallOff?.sopBuiltItemOrderline
                                                      ?.builtItem?.assemblyMinutes * assembly.amount
                                                : 'N/A'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                )}
            </div>
        </Modal>
    )
}
