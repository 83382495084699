import { styled } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { Assembly, WarehouseId } from '../../../../models/models'
import { toastFailure } from '../../../../util/toast'
import {
    assemblyTransferRequest,
    TransferType,
    TransferWorksheet,
} from '../../../../api/logistics-worksheet'
import DispatchTransferCard from './DispatchTransferCard'
import AssemblyTransferCard from './AssemblyTransferCard'
import ProductBinHoldingsModal from '../../../modals/bin-holdings-modal/ProductBinHoldingsModal'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setTransferView } from '../../../../store/warehouse-map-slice'
import { Urls } from '../../../../util/urls'
import InspectAssemblyModal from '../../../modals/inspect-assembly-modal/InspectAssemblyModal'
import { FilterBar } from '../Filterbar'
import { isFlatstation } from '../../../../util/util'

const Container = styled(Box)(() => ({}))
const HeaderContainer = styled(Box)(() => ({}))
const ContentContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
}))

export enum ActiveModal {
    AssemblyTransferBinHoldings = 'AssemblyTransferBinHoldings',
    DetailsModal = 'DetailsModal',
    None = 'None',
}

interface Props {
    transferWorksheets: TransferWorksheet[]
    fetchTransfers: () => Promise<void>
    warehouseId: WarehouseId
}

export default function Transfer({ transferWorksheets, fetchTransfers, warehouseId }: Props) {
    const [activeModal, setActiveModal] = useState<ActiveModal>(ActiveModal.None)
    const [modalAssembly, setModalAssembly] = useState<Assembly>()
    const dispatch = useDispatch()
    const history = useHistory()

    const openModal = (activeModal: ActiveModal, item: Assembly) => {
        setModalAssembly(item)
        setActiveModal(activeModal)
    }

    const closeModal = () => {
        setModalAssembly(undefined)
        setActiveModal(ActiveModal.None)
    }

    return (
        <Container>
            <FilterBar />
            {activeModal === ActiveModal.DetailsModal && modalAssembly && (
                <InspectAssemblyModal
                    assemblyId={modalAssembly.id}
                    closeModal={closeModal}
                    isOpen={true}
                />
            )}
            {activeModal === ActiveModal.AssemblyTransferBinHoldings && modalAssembly && (
                <ProductBinHoldingsModal
                    warehouseId={warehouseId}
                    closeModal={closeModal}
                    isOpen={activeModal === ActiveModal.AssemblyTransferBinHoldings}
                    onOk={() => {
                        assemblyTransferRequest(modalAssembly.id).then((response) => {
                            if (response.successful) {
                                closeModal()
                                fetchTransfers()
                            } else {
                                toastFailure('Could not complete the worksheet')
                            }
                        })
                    }}
                    productId={
                        modalAssembly?.builtItemCallOff.sopBuiltItemOrderline?.builtItem.product.id
                    }
                />
            )}

            <HeaderContainer></HeaderContainer>
            <ContentContainer>
                {transferWorksheets.map((transferWorksheet) => {
                    const item = transferWorksheet.item
                    if (transferWorksheet.type === TransferType.AssemblyTransfer) {
                        return (
                            <AssemblyTransferCard
                                warehouseId={warehouseId}
                                assembly={item}
                                onClick={() => {
                                    //for flatstations the assembly transfer should be moved on warehouse-map
                                    if (
                                        isFlatstation(
                                            item?.builtItemCallOff.sopBuiltItemOrderline?.builtItem
                                        )
                                    ) {
                                        dispatch(setTransferView(item))
                                        history.push(Urls.WarehouseMap)
                                        //for non-flatstations the assembly transfer should just show a modal with bin-locations
                                    } else {
                                        openModal(ActiveModal.AssemblyTransferBinHoldings, item)
                                    }
                                }}
                                openDetailsModal={() =>
                                    openModal(ActiveModal.DetailsModal, transferWorksheet.item)
                                }
                                key={transferWorksheet.item.id}
                            />
                        )
                    }
                })}
            </ContentContainer>
        </Container>
    )
}
